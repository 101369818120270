<ngb-toast *ngFor="let toast of toastService.toasts" class="d-flex justify-content-around alert" [ngClass]="toast.class" [autohide]="true"
  [delay]="toast.delay || 5000" (hidden)="toastService.remove(toast)">
  <ng-container [ngSwitch]="toast.type">
    <ng-template [ngSwitchCase]="'success'">
      <img class="d-inline-block pe-2 me-1 align-middle img-fluid" src="assets/images/notifier-success-tick.svg"/>
      <span class="message d-inline-block align-middle">{{toast.textOrTpl}}</span>
    </ng-template>
    <ng-template [ngSwitchCase]="'warning'">
      <img class="d-inline-block pe-2 me-1 align-middle img-fluid" src="assets/images/legacy/error-octagon.svg"/>
      <span class="message d-inline-block align-middle">{{toast.textOrTpl}}</span>
    </ng-template>
  </ng-container>
</ngb-toast>
