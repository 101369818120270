// Core Modules
import { Inject, Injectable, OnDestroy } from '@angular/core';

// Enums
import { LocalStorageEnum } from 'src/app/shared/enum/local-storage-keys.enum';
import { UserProfileFieldsEnum } from 'src/app/shared/enum/user-profile-fields.enum';

/**
 * This Storage Tracker service is used for tracking the change of clients through user settings dropdown
 * On duplicate tabs. This will make sure the client selection is same across duplicated tabs
 */
@Injectable({
  providedIn: 'root',
})
export class StorageTrackerService implements OnDestroy {

  constructor(@Inject('Window') private window: Window) {
    this.stop();
    this.start();
  }

  /**
   * A callback method that performs custom clean-up, invoked immediately before an instance is destroyed. 
   */
  public ngOnDestroy(): void {
    this.stop();
  }


  private start(): void {
    this.window.addEventListener('storage', this.storageEventListener.bind(this));
  }

  private storageEventListener(event: StorageEvent): void {
    // Reload the page on below conditions for multiple tabs open
    // 1. Reload on change in user_profile value in local storage
    // 2. Reload the page on clear of local storage
    if (event.storageArea === localStorage && ((event.key === LocalStorageEnum.USER_PROFILE && (event.newValue && JSON.parse(event.newValue)[UserProfileFieldsEnum.HG_CUSTOMER])) || (event.key === null && event.newValue === null))) {
      this.window.location.reload();
    }
  }

  public stop(): void {
    this.window.removeEventListener('storage', this.storageEventListener.bind(this));
  }
}
