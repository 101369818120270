<div class="container-fluid py-3 px-0">
  <app-page-title [pageTitleConfig]="pageTitle" (callBack)="callback()"></app-page-title>
  <div class="row">
    <div class="col-12">
      <app-toasts class="toast-container" aria-live="polite" aria-atomic="true"></app-toasts>
      <div class="row mt-4">
        <h2 class="d-inline-block fw-bold text-truncate font-size-24">Beta API Token</h2>
      </div>
        <app-list-view [settings]="tokenListViewSettings" [items]="tokens" [headers]="tokenListViewHeaderDef" 
        (listViewChange)="listCallBack($event)" [displayLoader]="displayLoader"></app-list-view>
    </div>
  </div>
</div>
<ng-template #delete_modal let-modal>
  <div class="modal-header">
    <h4 class="modal-title" id="modal-title">API Token Deletion</h4>
    <button type="button" class="btn-close close" aria-describedby="modal-title"
      (click)="modal.dismiss(false)"></button>
  </div>
  <div class="modal-body">
    <div class="row">
      <div class="col-12">
        <p class="fw-bold py-3">Are you sure you want to delete <code class="text-primary">{{ modalToken.id }}</code>?</p>
        <span class="text-danger">This operation can not be undone.</span>
      </div>
    </div>
  </div>
  <div class="modal-footer">
    <button type="button" class="btn btn-primary-hollow mx-3" (click)="modal.dismiss(false)">Cancel</button>
    <button type="button" class="btn btn-primary" (click)="modal.close(true)">Ok</button>
  </div>
</ng-template>

<ng-template #create_modal let-modal>
  <div class="modal-header">
    <h4 class="modal-title" id="modal-title">API Token Created</h4>
    <button type="button" class="btn-close close" aria-describedby="modal-title"
      (click)="modal.dismiss(false)"></button>
  </div>
  <div class="modal-body" #copyContainer>
    <div class="row">
      <div class="col-12">
      <p class="fw-bold py-3">
        <code>{{ modalToken.token }}</code>
        <span class="copy-icon" ngxClipboard [cbContent]="modalToken.token"
          [container]="copyContainer"><i class="bi bi-copy"> </i></span>
      </p>
      <span>Save your API tokens in a secure place. This is the only time you can access the plaintext of your token. If
        you lose it, you must delete it and create a new one.</span>
      </div>
    </div>
  </div>
  <div class="modal-footer">
    <button type="button" class="btn btn-primary" (click)="modal.close(true)">Ok</button>
  </div>
</ng-template>