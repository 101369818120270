// Core Modules
import { Component, OnDestroy, OnInit, TemplateRef, ViewChild } from '@angular/core';
import { HttpErrorResponse } from '@angular/common/http';

// External Modules & Services
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import TimeAgo from 'javascript-time-ago';
import { Subscription } from 'rxjs';

// Services
import { ApiService } from '../edp/api.service';
import { ToastsService } from '../shared/toasts/toasts.service';

// Models & Enums
import localeData from 'javascript-time-ago/locale/en.json';
import tokenConfigJson from './my-tokens-contents.json';
import { MainNavigationEnum } from '../shared/enum/main-navigation-url.enum';
import { ITableListViewSettingsModel } from '../shared/models/table/list-view-config.model';
import { IPageTitleModel } from '../shared/models/page-title-config-model';
import { IToken, ITokenList } from '../shared/models/auth-api-response.model';

TimeAgo.addLocale(localeData);
const timeAgo = new TimeAgo('en-US');

export class Token {
  id!: string;
  created!: Date;
  expires!: Date;

  get createdAgo() {
    return timeAgo.format(this.created);
  }

  get expiresAt() {
    if (!this.expires) {
      return 'never';
    }
    return timeAgo.format(this.expires);
  }
}

@Component({
  selector: 'app-my-tokens',
  templateUrl: './my-tokens.component.html',
  styleUrls: ['./my-tokens.component.scss'],
})
export class MyTokensComponent implements OnInit, OnDestroy {

  private readonly subscription = new Subscription();

  public me: any;
  public tokens!: Array<any>;
  public modalToken: any;
  public displayLoader:boolean = false;
  public tokenListViewHeaderDef: Record<string, object> = tokenConfigJson.listViewHeaderDef;
  public tokenListViewSettings: ITableListViewSettingsModel = {
    ariaLabel: 'Token Management List',
    ariaLabelDesc: 'Token Management List',
    cssClass: ['token-management-list-view'],
    header: {
      name: {
        id: 'tm-tbl-th-token-name',
      },
    },
    row: {
      actions: [{
        id: 'more',
        type: 'button',
        cssClass: 'my-token-list-action-dd',
        value: 'Delete user',
        options: [{
          id: 'my-delete-token',
          permissionOnly: ['token-write'],
        },
        ],
      },
      ],
    },
  };
  pageTitle?: IPageTitleModel;

  @ViewChild('delete_modal') deleteModel!: TemplateRef<any>;
  @ViewChild('create_modal') createModel!: TemplateRef<any>;
 

  constructor(
    private readonly apiService: ApiService, 
    private readonly modalService: NgbModal, 
    private readonly toastService: ToastsService) {}

  /**
   * A callback method that is invoked immediately after the default change detector has checked the directive's data-bound properties for the first time, and before any of the view or content children have been checked.
   */
  public ngOnInit(): void {
    this.setPageTitle();
    this.refreshList();
  }
  
  private setPageTitle() {
    this.pageTitle = {
      id: 'my-tokens-page-title-wrapper',
      showPageTitleBar: true,
      pageTitleWrapperClass: 'col-12 col-md-7 p-0 align-self-start text-start',
      pageTitleActionWrapperClass: 'ps-0 col-12 col-md-5 align-items-sm-start align-items-md-end',
      title: {
        label: 'Token Management',
        disableTooltip: true,
        id: 'tm-token-management-page-title-txt',
      },
      subTitle: {
        label: 'Allow users to view and create their own authenticated API Tokens.',
        disableTooltip: true,
        id: 'tm-token-management-page-sub-title-txt',
      },
      options: [
        {
          value: 'Create Token',
          type: 'button',
          id:'tm-token-management-create-campaign',
          permissionScope:'token-write',
        },
      ],
      backBtn: {
        stateName: `/${MainNavigationEnum.HOME}`,
        label: 'Home',
        id: 'back',
      },
    }; 
  }

  refreshList() {
    this.displayLoader = true;
    this.subscription.add(this.apiService.listTokens().subscribe({
      next: (data: ITokenList)=> {
        this.displayLoader = false;
        this.tokens = data.items.map((item: IToken) => {
          return Object.assign(new Token(), {
            id: item.id,
            created: item.createDate ? new Date(Date.parse(item.createDate)) : null,
            expires: item.expires ? new Date(Date.parse(item.expires as string)) : null,
          });
        });
      },
      error: (error: HttpErrorResponse) => {
        this.displayLoader = false;
        console.log('<MyTokensComponent> - <refreshList> - Failed while fetching list tokens', error);
      },
    }));
  }

  public onDelete(content: TemplateRef<any> | string, token: { id: any; }) {
    this.modalToken = token;
    const modal = this.modalService.open(content, { ariaLabelledBy: 'modal-basic-title' });
    modal.result.then(
      (result) => {
        if (result) {
          this.subscription.add(this.apiService.deleteToken(token.id).subscribe({
            next: ()=>{
              this.refreshList();
            },
            error: (e: HttpErrorResponse) => {
              let errorMessage = e.error.message ? e.error.message : 'Error occurred during deleting';
              console.log('<MyTokensComponent> - <onDelete> - Error occurred during deleting token', e);
              this.showFailureToast(errorMessage);
            },
            complete: ()=> {
              this.showSuccessToast('Token deleted successfully.');
            },
          }));
        }
      },
      () => {},
    );
  }

  public onCreate(content: TemplateRef<any> | string) {
    this.subscription.add(this.apiService.newToken().subscribe({
      next:((token: IToken) => {
        this.modalToken = token;
        const modal = this.modalService.open(content, { ariaLabelledBy: 'token-create-model' });
        modal.result.then(
          () => {
            this.refreshList();
            this.showSuccessToast('Token created successfully.');
          })
          .catch(()=> {
            this.refreshList();
            this.showSuccessToast('Token created successfully.');
          },
          );
      }),
      error: (e: HttpErrorResponse) => {
        let errorMessage = e.error.message ? e.error.message : 'Error occurred during creating token';
        console.log('<MyTokenComponent> - <createToken> Error occurred while creating token...', errorMessage);
        this.showFailureToast(errorMessage);
      },
    }));
  }

  showSuccessToast(message: string) {
    this.toastService.show(message, { class: 'ui-notification alert-panel success text-dark', type: 'success' });
  }

  showFailureToast(message: string) {
    this.toastService.show(message, { class: 'ui-notification alert-panel warning text-dark', type: 'warning' });
  }

  callback() {
    this.onCreate(this.createModel);
  }

  listCallBack(event: any) {
    if (event.value === 'Delete user') {
      this.onDelete(this.deleteModel, event.data);
    }
  }

  ngOnDestroy(): void {
    this.subscription.unsubscribe();
  }
}
