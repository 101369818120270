// Core Modules
import { HttpErrorResponse, HttpEvent, HttpHandler, HttpInterceptor, HttpRequest } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Router } from '@angular/router';

// External Modules
import { BehaviorSubject, catchError, filter, Observable, retry, switchMap, take } from 'rxjs';

// Services
import { AuthService } from '../services/auth.service';
import { ConfigService } from '../services/config.service';
import { ErrorHandlerService } from '../services/error-handler.service';
import { SessionManagerService } from '../services/session-manager.service';

// Models
import { AppEndpointsModel } from '../../shared/models/endpoints.config.model';

// Enums
import { MainNavigationEnum } from '../../shared/enum/main-navigation-url.enum';

/**
 * Auth Interceptor
 */
@Injectable()
export class AuthInterceptor implements HttpInterceptor {
  /**
   * holds whitelisted endpoint
   */
  private whiteListUrls: string[] = [];
  /**
   * refreshTokenInProgress to get boolean: true/false
   */
  private refreshTokenInProgress = false;
  /**
   * appEndpoints to get app endpoints
   */
  private appEndpoints: AppEndpointsModel;
  /**
   * authContents to get auth contents
   */
  private authContents: any;
 
  /**
   * refresh token handler
   */
  private refreshTokenSubject: BehaviorSubject<any> = new BehaviorSubject<any>(null);

  /**
   * The constructor method
   * @param auth authentication
   * @param configService config service
   * @param errorHandlerService error handler service
   * @param router router
   * @param sessionManager session manager service
   */
  constructor(
    private readonly auth: AuthService,
    private readonly configService: ConfigService,
    private readonly errorHandlerService: ErrorHandlerService,
    private readonly router: Router,
    private readonly sessionManager: SessionManagerService,
  ) {
    this.appEndpoints = this.configService.getEndpointsByModule('appEndpoints');
    this.authContents = this.configService.getContents('auth');
    // build whitelisted endpoints
    this.whiteListUrls = [
      `${this.configService.getAPIBaseUrl}${this.appEndpoints.legacyFederatedDomain}`,
      `${this.configService.getAPIBaseUrl}${this.appEndpoints.clientList}`,
      `${this.configService.getAPIBaseUrl}${this.appEndpoints.sessionRefresh}`,
    ];
  }

  /**
   * Interceptor implementation
   * @param req http request
   * @param next http handler
   * @returns intercept
   */
  public intercept(req: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
    // Handle request
    if (!this.whiteListUrls.includes(req.url)) {
      req = this.addAuthHeader(req);
    }
    // Handle response
    return <any>next.handle(req).pipe(
      retry({ count: this.authContents.retryCount, delay: this.authContents.delay }),
      catchError((error: HttpErrorResponse) => {
        return this.handleResponseError(error, req, next);
      }));
  }

  /**
   * Utility method to handle parallel requests
   * E.g. Let's say 5 requests are sent and return 401,
   * then 1 refreshToken is performed, and 5 requests again
   * @returns
   */
  private refreshToken(request: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any> | HttpErrorResponse> {
    if (this.refreshTokenInProgress) {
      return this.refreshTokenSubject.pipe(
        filter(userProfile => userProfile != null),
        take(1),
        switchMap(() => {
          return next.handle(this.addAuthHeader(request));
        }));
    } else {
      this.refreshTokenInProgress = true;
      this.refreshTokenSubject.next(null);
      return this.auth.invokeRefreshToken().pipe(
        retry({ count: this.authContents.retryCount, delay: this.authContents.delay }),
        switchMap(() => {
          this.refreshTokenInProgress = false;
          const userProfile = this.auth.getUserProfile();
          this.refreshTokenSubject.next(userProfile);
          request = this.addAuthHeader(request);
          return next.handle(request);
        }),
        catchError((e) => {
          this.refreshTokenInProgress = false;
          this.navigateToLogin();
          return this.errorHandlerService.errorHandler(e);
        }),
      );
    }
  }

  /**
   * method to navigate to login
   */
  private navigateToLogin(): void {
    this.auth.isSessionValid().pipe(take(1)).subscribe((currentSessionStatus: boolean) => {
      console.log(`<AuthInterceptor> - <navigateToLogin> - Current status value is: ${currentSessionStatus}`);
      if (!currentSessionStatus) {
        this.sessionManager.stopWatching();
        this.auth.logout();
      }
    });

  }

  /**
   * method to add auth header
   * @param req http request
   * @returns auth header
   */
  public addAuthHeader(req: HttpRequest<any>): HttpRequest<any> {
    const authToken = this.auth.getAuthToken;
    const customerId = this.auth.getCustomerId();
    if (authToken) {
      req = req.clone({
        setHeaders: {
          Authorization: `Bearer ${authToken}`,
        },
      });
    }
    if (customerId) {
      req = req.clone({
        setHeaders: {
          'hg-customer-code': customerId,
        },
      });
    }
    return req;
  }

  /**
   * method to re-authentication handler
   * @param error error
   * @param request http request
   * @param next http handler
   * @returns refresh token
   */
  private reAuthenticationHandler(error: any, request: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any> | HttpErrorResponse> {
    return this.auth.isSessionValid().pipe(
      take(1),
      switchMap((sessionStatus) => {
        if (sessionStatus) return this.refreshToken(request, next);
        else {
          this.navigateToLogin();
          return this.errorHandlerService.errorHandler(error);      
        }
      }),
    );
  }

  /**
   * method to handle response error
   * @param error error
   * @param request http request
   * @param next http handler
   * @returns error handler
   */
  private handleResponseError(error: any, request: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any> | HttpErrorResponse> {
    // logout if /refresh, auth/me or auth/me/clients api fail
    if ((request.url.includes(`${this.appEndpoints.sessionRefresh}`) || request.url.includes(`${this.appEndpoints.userScopes}`)) && !request.url.includes(`${this.appEndpoints.userScopes}/tokens`) ) {
      this.sessionManager.stopWatching();
      this.auth.logout();
      return this.errorHandlerService.errorHandler(error);
    }

    if (error instanceof HttpErrorResponse) {
      // Invalid token error
      if (error.status === 401 || error.status === 503) return this.reAuthenticationHandler(error, request, next);
      // Redirect to error page
      if (error.status === 403) this.router.navigate([`/${MainNavigationEnum.UNAUTHORIZED}`]);
    }
    return this.errorHandlerService.errorHandler(error);
  }
}
