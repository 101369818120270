// Core Modules
import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';

// External Modules
import { catchError, Observable, of, tap } from 'rxjs';

// Services
import { CacheService } from './cache.service';
import { ErrorHandlerService } from './error-handler.service';

/**
 * Http Adapter Service
 */
@Injectable()
export class HttpAdapterService {

  /**
   * The constructor method
   * @param cacheService cache service to store data
   * @param errorHandlerService error handler service to handle errors
   * @param httpClient http client for api calls
   */
  constructor(
    private readonly cacheService: CacheService,
    private readonly errorHandlerService: ErrorHandlerService,
    private readonly httpClient: HttpClient,
  ) { }

  /**
   * Constructs a GET request that interprets the body as a JSON object
   * and returns the response body
   * @param url API url
   * @param cacheKey It is the combination of request criteria and clientId,
   * if it is passed then this method will set and get the data to and from the cache
   * @returns An Observable of the HttpResponse
   */
  public get<T>(url: string, options?:Record<string, any>, cacheKey?: string): Observable<T> {

    if (cacheKey && this.cacheService.isCacheExists(cacheKey)) {
      const cachedResp = this.cacheService.getCacheData(cacheKey);
      cachedResp.cacheKey = cacheKey;
      return of(cachedResp);
    }

    return this.httpClient.get<T>(url, options).pipe(
      tap(result  => {
        if (cacheKey) {
          this.cacheService.createCache(cacheKey).put(cacheKey, result);
        }
        return result;
      }),
      catchError((err)=> {
        return this.errorHandlerService.errorHandler(err);
      }),
    );
  }

  /**
   * Constructs a POST request that interprets the body as a JSON object
   * and returns an observable of the response
   * @param url API url
   * @param body Request criteria
   * @returns An Observable of the HttpResponse
   */
  public post<T>(url: string, body: Record<string, any> | Record<string, any>[]): Observable<T> {
    return this.httpClient.post<T>(url, body).pipe(
      tap(result  => result),
      catchError((err) => {
        return this.errorHandlerService.errorHandler(err);
      }),
    );
  }

  /**
   * Constructs a PUT request that interprets the body as a JSON object
   * and returns an observable of the response
   * @param url API url
   * @param body Request criteria
   * @returns An Observable of the HttpResponse
   */
  public put<T>(url: string, body: Record<string, any> | Record<string, any>[]): Observable<T> {
    return this.httpClient.put<T>(url, body).pipe(
      tap(result  => result),
      catchError((err) => {
        return this.errorHandlerService.errorHandler(err);
      }),
    );
  }

  /**
   * Constructs a PATCH request that interprets the body as a JSON object
   * and returns an observable of the response
   * @param url API url
   * @param body Request criteria
   * @returns An Observable of the HttpResponse
   */
  public patch<T>(url: string, body: Record<string, any> | Record<string, any>[]): Observable<T> {
    return this.httpClient.patch<T>(url, body).pipe(
      tap(result  => result),
      catchError((err) => {
        return this.errorHandlerService.errorHandler(err);
      }),
    );
  }

  /**
   * Constructs a DELETE request that interprets the body as a JSON object
   * and returns an observable of the response
   * @param url API url
   * @param body Request criteria
   * @returns An Observable of the HttpResponse
   */
  public delete<T>(url: string, body: Record<string, any>): Observable<T> {
    return this.httpClient.delete<T>(url, body).pipe(
      tap(result  => result),
      catchError((err) => {
        return this.errorHandlerService.errorHandler(err);
      }),
    );
  }

}
