// Core Modules
import { inject } from '@angular/core';
import { ResolveFn } from '@angular/router';

// Services
import { AuthService } from '../services/auth.service';

/**
 * Federated Domain Resolver
 */
export const FederatedDomainResolver : ResolveFn<Array<string>> = () => {
  /**
   * The constructor method
   * @param auth authentication
   * @returns legacy federated domains
   */
  const authService = inject(AuthService);
  return authService.getLegacyFederatedDomains();
};
