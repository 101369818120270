// Models
import { AppEnvironmentConfig } from 'src/app/shared/models/environment-config.model';

/**
 * App environment config for UAT
 */
export const environment: AppEnvironmentConfig = {
  PRODUCT_NAME: 'ADMIN',
  API: 'https://api.exp-uat.mercuryhealthcare.com/api',
  AUTH0: {
    domain: 'auth0.mercuryhealthcare.com',
    tenant: 'healthgrades',
    clientId: 'Oxne2jdswgHhgkc7o0WUJ9mm4tIKkNDZ',
  },
  SESSION: {
    refreshSessionInterval: 600, // Refresh API sent every 10 minutes
    logoutNotifyExpiryTime: 30, // 30 secs; Notification waits this time for a user action. If no-action, the user is logged off
    maxIdleWaitTime: 900, // (In Seconds) 15 minutes; When there is no events from a user, a notification is shown
  },
  CLIENTLIST: 200,
  LOCAL_STORAGE_ENCRYPTION: 'mM0rKjdQwzWIXCw', // RANDOM TEXT USED TO ENCRYPT LOCAL STORAGE VALUES
};
