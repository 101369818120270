// Core Modules
import { Injectable } from '@angular/core';

// External Modules
import CryptoJS from 'crypto-js';

// Services
import { ConfigService } from './config.service';

/**
 * Crypto Service
 */
@Injectable({
  providedIn: 'root',
})
export class CryptoService {

  /**
   * The constructor method
   * @param configService config service
   */
  constructor(private readonly configService: ConfigService) {}

  /**
   * This method is used for encrypting message with encryptKey keyword
   * The Cipher Algorithms : AES
   * The Advanced Encryption Standard (AES) is a U.S. Federal Information Processing Standard (FIPS). 
   * It was selected after a 5-year process where 15 competing designs were evaluated.
   * @param {string} message
   * @return {string} 
   * @memberof CryptoService
   */
  public encrypt(message: string): string {
    return CryptoJS.AES.encrypt(message, this.configService.encryptKey).toString();
  }

  /**
   * This method is used for decrypting message with encryptKey keyword
   *
   * @param {string} cipherText
   * @return {*}  {string}
   * @memberof CryptoService
   */
  public decrypt(cipherText:string): string {
    const bytes  = CryptoJS.AES.decrypt(cipherText, this.configService.encryptKey);
    return bytes.toString(CryptoJS.enc.Utf8);
  }

}
