// Angular Code Modules
import { Injectable } from '@angular/core';
import { HttpClient, HttpParams } from '@angular/common/http';

// External Modules
import { Observable, catchError, map } from 'rxjs';

// Services
import { ConfigService } from '../core/services/config.service';
import { ErrorHandlerService } from '../core/services/error-handler.service';

// Models & Enums
import { IProducts } from '../shared/models/products/product-config.model';
import { IClientListResponse, IClientRoles } from '../user-management/models/user.model';
import { environment } from 'src/environments/environment';
import { IToken, ITokenList, IUserDetails, IUsersList } from '../shared/models/auth-api-response.model';

@Injectable({ providedIn: 'root' })
export class ApiService {
  constructor(
    private readonly configService: ConfigService,
    private readonly errorHandlerService: ErrorHandlerService,
    private readonly httpClient: HttpClient) {}

  get http(): HttpClient {
    return this.httpClient;
  }

  me(): Observable<IUserDetails> {
    return this.httpClient.get<IUserDetails>(`${this.configService.getAPIBaseUrl}/auth/me`)
      .pipe(map(result => result),
        catchError(this.errorHandlerService.errorHandler));
  }

  clients(): Observable<{}> {
    return this.httpClient.get(`${this.configService.getAPIBaseUrl}/auth/me/clients`)
      .pipe(map(result => result),
        catchError(this.errorHandlerService.errorHandler));
  }

  listTokens(): Observable<ITokenList> {
    return this.httpClient.get<ITokenList>(`${this.configService.getAPIBaseUrl}/auth/me/tokens`)
      .pipe(map(result => result),
        catchError(this.errorHandlerService.errorHandler));
  }

  newToken(duration = null): Observable<IToken> {
    return this.httpClient.post<IToken>(`${this.configService.getAPIBaseUrl}/auth/me/tokens`, { duration })
      .pipe(map(result => result),
        catchError(this.errorHandlerService.errorHandler));
  }

  deleteToken(id:string): Observable<{}> {
    return this.httpClient.delete(`${this.configService.getAPIBaseUrl}/auth/me/tokens/` + id)
      .pipe(map(result => result),
        catchError(this.errorHandlerService.errorHandler));
  }

  getUsers(userParam: any) : Observable<IUsersList> {
    const { 
      searchTerm, 
      pageSize, 
      page,
      sort,
    } = userParam;
    const appUrl = `${this.configService.getAPIBaseUrl}/auth/users`;
    const queryParams = { 
      ...searchTerm && { searchTerm },
      ...pageSize && { pageSize },
      ...page && { page },
      ...sort && { sort },
    };
    return this.httpClient.get<IUsersList>(appUrl, { params: new HttpParams().appendAll(queryParams) })
      .pipe(map(result => result),
        catchError(this.errorHandlerService.errorHandler));
  }

  getClients(pageNum: number): Observable<IClientListResponse> {
    return this.httpClient.get<IClientListResponse>(
      `${this.configService.getAPIBaseUrl}/auth/clients?pageSize=${environment.CLIENTLIST}&page=${pageNum}`,
    );
  }

  getRoles() : Observable<IClientRoles> {
    return this.httpClient.get<IClientRoles>(`${this.configService.getAPIBaseUrl}/auth/roles?pageSize=100`);
  }
  getProductsBasedOnClientId(clientId: number): Observable<IProducts[]> {
    return this.httpClient.get<IProducts[]>(`${this.configService.getAPIBaseUrl}/auth/clients/${clientId}/products`);
  }
  getUser(email:string) : Observable<{}> {
    return this.httpClient.get(`${this.configService.getAPIBaseUrl}/auth/users/${email}`);
  }
  deleteUser(email:string) {
    return this.httpClient.delete(`${this.configService.getAPIBaseUrl}/auth/users/${email}`);
  }
  createUser(user:any) {
    return this.httpClient.post<any>(`${this.configService.getAPIBaseUrl}/auth/users`, user);
  }
  updateUser(user:any) {
    return this.httpClient.put<any>(`${this.configService.getAPIBaseUrl}/auth/users/${user.email}`, user);
  }
}
