// Core Modules
import { BrowserModule } from '@angular/platform-browser';
import { FormsModule } from '@angular/forms';
import { HttpClientModule } from '@angular/common/http';
import { NgModule } from '@angular/core';

// External Modules
import { CacheFactory } from 'cachefactory';
import { ClipboardModule } from 'ngx-clipboard';
import { NgxPermissionsModule } from 'ngx-permissions';
import { provideUserIdleConfig } from 'angular-user-idle';

// Modules
import { AppRoutingModule } from './app-routing.module';
import { CoreModule } from './core/core.module';
import { SharedModule } from './shared/shared.module';

// Components
import { AppComponent } from './app.component';
import { AdminHomeComponent } from './home/home.component';
import { MyTokensComponent } from './my-tokens/my-tokens.component';

// Environment
import { environment } from 'src/environments/environment';

/**
 * MODULES is used to import core module and shared module
 */
const MODULES = [
  CoreModule,
  SharedModule,
];

@NgModule({
  declarations: [
    AdminHomeComponent,
    AppComponent,
    MyTokensComponent,
  ],
  imports: [
    AppRoutingModule,
    BrowserModule,
    ClipboardModule,
    FormsModule,
    HttpClientModule,
    ...MODULES,
    NgxPermissionsModule.forRoot(),
  ],
  providers: [
    CacheFactory,
    { 
      provide: 'Window', 
      useValue: window, 
    },
    provideUserIdleConfig({ 
      idle: environment.SESSION.maxIdleWaitTime, 
      timeout: environment.SESSION.logoutNotifyExpiryTime, 
      ping: environment.SESSION.refreshSessionInterval, 
    }),
  ],
  bootstrap: [AppComponent],
})
export class AppModule { }
