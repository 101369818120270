// Angular Modules
import { Component, OnInit } from '@angular/core';

// Models
import { IUserProfileBasedOnFields } from '../../models/auth0-types.model';
import { HeaderContentModel } from '../../models/header-content.model';

// Enums
import { UserProfileFieldsEnum } from '../../enum/user-profile-fields.enum';

// Services
import { AuthService } from '../../../core/services/auth.service';
import { ConfigService } from '../../../core/services/config.service';
import { SessionManagerService } from '../../../core/services/session-manager.service';


/**
 * User Settings Component.
 */
@Component({
  selector: 'app-user-settings',
  templateUrl: './user-settings.component.html',
  styleUrls: ['./user-settings.component.scss'],
})
export class UserSettingsComponent implements OnInit {
  /**
   * headerContents to get header contents
   */
  public headerContents: HeaderContentModel;
  /**
   * userEmailName to get user email name
   */
  public userEmailName?: string;
  /**
   * userProfile to get user profile
   */
  private userProfile?: IUserProfileBasedOnFields;
  /**
   * userNameInitials to get user name initial
   */
  public userNameInitials?: string;
  /**
   * @param configService configuration service
   */
  constructor(
    private readonly auth: AuthService,
    private readonly configService: ConfigService,
    private readonly sessionManager:SessionManagerService,
  ) {
    // Load the header static contents from app-contents.json file
    this.headerContents = this.configService.getContents('header');
  }

  /**
   * The ngOnIt method.
   */
  public ngOnInit(): void {
    this.userProfile = this.auth.getUserProfileBasedOnFields([UserProfileFieldsEnum.EMAIL, UserProfileFieldsEnum.HG_CUSTOMER]);
    this.userEmailName = this.userProfile?.email ? this.userProfile.email : 'NOT_AVAILABLE';
    this.getUserNameInitials();
  }

  /**
   * get initials of user name for profile photo
   */
  public getUserNameInitials(): void {
    if (this.userEmailName && this.userEmailName !== 'NOT_AVAILABLE') {
      this.userNameInitials = this.userEmailName.charAt(0).toUpperCase();
    }
  }

  /**
   * This method is used for logging out from Auth0.
   *
   * @memberof HeaderComponent
   */
  public logout(): void {
    this.sessionManager.stopWatching();
    this.auth.logout();
  }

}