// Core Modules
import { Injectable } from '@angular/core';

// Models
import { AppEndpointsModel } from 'src/app/shared/models/endpoints.config.model';
import { AppEnvironmentConfig } from '../../shared/models/environment-config.model';
import { IAuth0Configuration } from 'src/app/shared/models/auth0-types.model';

// Environment
import { environment } from '../../../environments/environment';

// App Contents
import appContents from '../../../assets/app-contents.json';

/**
 * Config Service
 */
@Injectable()
export class ConfigService {

  /**
   * appEnvironment variable to hold the environment configurations
   */
  private appEnvironment: AppEnvironmentConfig;
  /**
   * Load all the app constants into a variables
   */
  private contents: any;

  /**
   * The constructor method
   */
  constructor() {
    this.appEnvironment = environment;
    this.contents = appContents;
  }

  /**
   * Utility method to get the endpoints by module name
   * @param module Module name to get endpoints list
   * @returns Module wise endpoint list
   */
  public getEndpointsByModule(module: string): AppEndpointsModel {
    return this.contents.endpoints[module];
  }

  /**
   * Get the Refresh Session Interval config from environment. default 10 Mins
   * @returns duration
   */
  public get refreshSessionInterval(): number {
    return environment.SESSION.refreshSessionInterval;
  }

  /**
   * Get logoutNotifyExpiryTime by default 30 secs.
   * Notification waits this time for a user action. If no-action, we log the user off
   * @returns duration
   */
  public get logoutNotifyExpiryTime(): number {
    return environment.SESSION.logoutNotifyExpiryTime;
  }

  /**
   * @returns encryptKey
   * Used for encrypting, decrypting the User Profile, Meta Information
   */
  public get encryptKey(): string {
    return environment.LOCAL_STORAGE_ENCRYPTION;
  }

  /**
   * @returns string
   * Auth0 Tenant information
   */
  public get auth0Tenant(): string {
    return environment.AUTH0.tenant;
  }

  /**
   * @returns string
   * Auth0 Domain information
   */
  public get auth0Domain(): string {
    return environment.AUTH0.domain;
  }

  /**
   * Get the app content by section name,
   * section name should be parent field from the app-contents.json file
   * @sectionName section name to return content
   * @returns content data by section name, e.g. sectionName=footer OR header
   */
  public getContents(sectionName: string): any {
    return this.contents[sectionName];
  }

  /**
   * @returns API base URL
   */
  public get getAPIBaseUrl(): string {
    return environment.API;
  }

  /**
   * method to get auth0 configurations
  * @returns Auth module configurations
  */
  public get getAuthConfig(): IAuth0Configuration {
    return environment.AUTH0;
  }

}
