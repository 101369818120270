// Core Modules
import { Component } from '@angular/core';

// Services
import { ConfigService } from 'src/app/core/services/config.service';

/**
 * Footer Component
 */
@Component({
  selector: 'app-footer',
  templateUrl: './footer.component.html',
  styleUrls: ['./footer.component.scss'],
})
export class FooterComponent {
  /**
   * Holds the footer static contents
   */
  public copyText?: string;
  public footerContents: any;

  /**
   * The constructor method
   * @param configService config service
   */
  constructor(private configService: ConfigService) {
    const currentYear = new Date().getFullYear();
    // Load the footer static contents from app-contents.json file
    this.footerContents = this.configService.getContents('footer');
    this.copyText = `Copyright ${currentYear} ${this.footerContents.copyText}`;
  }
}