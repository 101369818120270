import { Component, EventEmitter, Input, OnDestroy, OnInit, Output } from '@angular/core';
import { Observable, Subject, Subscription, isObservable } from 'rxjs';

import { debounceTime, distinctUntilChanged } from 'rxjs/operators';
import { IPageTitleModel } from '../models/page-title-config-model';
/**
 * Page Title Component
 */
@Component({
  selector: 'app-page-title',
  templateUrl: './page-title.component.html',
  styleUrls: ['./page-title.component.scss'],
})
export class PageTitleComponent implements OnInit, OnDestroy {

  private subscription: Subscription = new Subscription();
  /**
   * Holds the static contents of main navigation component
   */
  public lookupWord?: string;
  public badgeMessageText?: string;

  /**
   * Emit button function
   */
  @Output() callBack: EventEmitter<Record<string, string | undefined>> = new EventEmitter<Record<string, string | undefined>>();

  /**
   * Emit search input key up callback
   */
  @Output() searchCallBack: EventEmitter<string> = new EventEmitter<string>();

  /**
   * Emit dropdown selected value
   */
  @Output() ddlSelectedItem: EventEmitter<string> = new EventEmitter<string>();

  /**
   * Input variable for pageTitleConfig.
   */
  @Input() pageTitleConfig?: IPageTitleModel;

  @Input() badgeMessage?: Observable<string>;

  /**
   * search string subject behavior variable.
   */
  searchStringUpdate = new Subject<string>();

  /**
   * The constructor method
   */
  constructor() {
    // Debounce search. It will make a delay for 300 milliseconds to emit the event.
    this.searchStringUpdate.pipe(
      debounceTime(300),
      distinctUntilChanged())
      .subscribe(value => {
        this.searchCallBack.emit(value);
      });
  }

  /**
   * A callback method that is invoked immediately after the default change detector has checked the directive's data-bound properties for the first time, and before any of the view or content children have been checked.
   */
  public ngOnInit(): void {
    if (isObservable(this.badgeMessage)) {
      this.subscription.add(this.badgeMessage?.subscribe({
        next: (msg: string) => {
          this.badgeMessageText = msg;
        },
        error: (error: Error) => {
          console.log('Error occurred', error);
        },
        complete: ()=> {
          console.log('completed...');
        },
      }));
    }
  }

  /**
   * new button event emitter
   * @param value button click value
   */
  btnClickCallback(param: Record<string, string | undefined>) {
    this.callBack.emit(param);
  }

  /**
   * lookup search event emitter
   */
  search() {
    this.searchCallBack.emit(this.lookupWord);
  }

  /**
   * dropdown selected item event emitter
   * @param item selected item value
   */
  selectItem(item?: string) {
    this.ddlSelectedItem.emit(item);
  }

  /**
   * A callback method that performs custom clean-up, invoked immediately before an instance is destroyed. 
   */
  public ngOnDestroy(): void {
    this.subscription.unsubscribe();
  }
}
