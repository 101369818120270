// Core Modules
import { CommonModule } from '@angular/common';
import { HTTP_INTERCEPTORS } from '@angular/common/http';
import { NgModule } from '@angular/core';

// External Modules
import { NgxPermissionsModule } from 'ngx-permissions';

// Guards, Interceptor
import { AuthGuardCanActivate } from './guards/auth.guard';
import { AuthInterceptor } from './interceptors/auth.interceptor';
import { PermissionGuardCanActivate } from './guards/permission.guard';

// Services
import { AuthService } from './services/auth.service';
import { CacheService } from './services/cache.service';
import { ConfigService } from './services/config.service';
import { ErrorHandlerService } from './services/error-handler.service';
import { HttpAdapterService } from './services/http-adapter.service';

@NgModule({
  declarations: [],
  imports: [
    CommonModule, 
    NgxPermissionsModule,
  ],
  providers: [
    AuthGuardCanActivate,
    AuthService,
    CacheService,
    ConfigService,
    ErrorHandlerService,
    HttpAdapterService,
    PermissionGuardCanActivate,
    {
      provide: HTTP_INTERCEPTORS,
      useClass: AuthInterceptor,
      multi: true,
    },
  ],
  exports: [],
})
export class CoreModule {}
