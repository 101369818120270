// Core Modules
import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';

// Guards, Resolver
import { AuthGuard } from './core/guards/auth.guard';
import { FederatedDomainResolver } from './core/guards/federated-domain-resolver.guard';
import { PermissionGuard } from './core/guards/permission.guard';
import { SessionValidationGuard } from './core/guards/session-validation.guard';

// Components
import { AdminHomeComponent } from './home/home.component';
import { AuthLoginComponent } from './shared/auth-login/auth-login.component';
import { MyTokensComponent } from './my-tokens/my-tokens.component';
import { UnauthorizedComponent } from './shared/unauthorized/unauthorized.component';

// Enums
import { MainNavigationEnum } from './shared/enum/main-navigation-url.enum';

// 1. /login
// 2. /home
// 3. /api-tokens
// 4. /user-management
// 5. /unauthorized
const routes: Routes = [
  {
    path: MainNavigationEnum.LOGIN,
    component: AuthLoginComponent,
    canActivate: [AuthGuard],
    resolve: { data: FederatedDomainResolver },
  },
  {
    path: MainNavigationEnum.HOME,
    component: AdminHomeComponent,
    data: {
      permissions: {
        only: ['admin-app-read'],
        redirectTo: MainNavigationEnum.UNAUTHORIZED,
      },
    },
    canActivate: [SessionValidationGuard, PermissionGuard],
  },
  {
    path: MainNavigationEnum.API_TOKENS,
    component: MyTokensComponent,
    data: {
      permissions: {
        only: ['token-read'],
        redirectTo: MainNavigationEnum.UNAUTHORIZED,
      },
    },
    canActivate: [SessionValidationGuard, PermissionGuard],
  },
  {
    path: MainNavigationEnum.USER_MANAGEMENT,
    loadChildren: () => import('./user-management/user-management.module').then(m => m.UserManagementModule),
    data: {
      permissions: {
        only: ['user-read'],
        redirectTo: MainNavigationEnum.UNAUTHORIZED,
      },
    },
    canActivate: [SessionValidationGuard, PermissionGuard],
  },
  {
    path: MainNavigationEnum.UNAUTHORIZED,
    component: UnauthorizedComponent,
    pathMatch: 'full',
    canActivate: [SessionValidationGuard],
  },
  // Redirect to /login if match is ""
  { path: '', redirectTo: `/${MainNavigationEnum.LOGIN}`, pathMatch: 'full' },
  // Redirect to /login if no route match is found
  { path: '**', redirectTo: `/${MainNavigationEnum.LOGIN}` },
];

@NgModule({
  imports: [RouterModule.forRoot(routes)],
  exports: [RouterModule],
})
export class AppRoutingModule {}
