import { Component, TemplateRef } from '@angular/core';
import { ToastsService } from './toasts.service';

@Component({
  selector: 'app-toasts',
  templateUrl: './toasts-container.component.html',
})
export class ToastsContainerComponent {
  constructor(public toastService: ToastsService) { }

  isTemplate(toast: any) {
    return toast.textOrTpl instanceof TemplateRef;
  }
}