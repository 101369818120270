// Core Modules
import { HttpClient } from '@angular/common/http';
import { HttpHeaders } from '@angular/common/http';
import { Inject, Injectable, OnDestroy } from '@angular/core';
import { Router } from '@angular/router';

// External Modules
import Auth0Lock from 'auth0-lock';
import { BehaviorSubject, catchError, forkJoin, from, map, Observable, of, Subject, Subscription, switchMap, tap, throwError } from 'rxjs';
import { CookieService } from 'ngx-cookie-service';

// Services
import { AccessControlService } from '../permission/service/access-control.service';
import { ConfigService } from './config.service';
import { CryptoService } from './crypto.service';
import { ErrorHandlerService } from './error-handler.service';
import { StorageTrackerService } from './storage-tracker.service';

// Models
import { AppEndpointsModel } from '../../shared/models/endpoints.config.model';
import { IAuth0ClientInfo, IAuth0Profile, IUserProfileBasedOnFields, IUserProfileWithClientList } from '../../shared/models/auth0-types.model';

// Enums
import { LocalStorageEnum } from '../../shared/enum/local-storage-keys.enum';
import { MainNavigationEnum } from '../../../app/shared/enum/main-navigation-url.enum';
import { UserProfileFieldsEnum } from '../../shared/enum/user-profile-fields.enum';

// Environment
import { environment } from '../../../environments/environment';

/**
 * Auth Service
 */
@Injectable({
  providedIn: 'root',
})
export class AuthService implements OnDestroy {
  /**
   * userProfile to get user profile
   */
  private userProfile: IAuth0Profile;

  /**
   * appEndpoints to get appEndpoints
   */
  private appEndpoints: AppEndpointsModel;

  /**
   * lock to get auth0 lock static
   */
  private lock?: Auth0LockStatic;

  /**
   * apiBaseUrl to get apibaseurl
   */
  private apiBaseUrl: string;

  /**
   * userEmail for user email
   */
  private userEmail?: string;

  /**
   * clientList to get client list
   */
  public clientList: IAuth0ClientInfo[] = [];

  /**
   * logoutSubject$ is a observable of logout status to stop session events
   */
  public logoutSubject$: Subject<boolean> = new Subject<boolean>();

  /**
   * Get the isSessionValidSubject state : true/false.
   */
  private isSessionValidSubject = new BehaviorSubject<boolean>(false);

  /**
   * isSessionValid$ is a observable which emits the current session status
   */
  public isSessionValid$ = this.isSessionValidSubject.asObservable();

  /**
   * authenticationInProgressSubject is a observable of current session status
   */
  public authenticationInProgressSubject = new BehaviorSubject<boolean>(false);

  /**
   * isAuthenticationInProgress$ is a observable which emits the current session status
   */
  public isAuthenticationInProgress$ = this.authenticationInProgressSubject.asObservable();

  /**
   * clientNavigationInProgress is a observable of Client List Status
   */
  public clientNavigationInProgressSubject = new BehaviorSubject<boolean>(false);

  /**
   * clientNavigationInProgress$ is a observable which emits the Client List Status
   */
  public isClientNavigationInProgress$ = this.clientNavigationInProgressSubject.asObservable();

  /**
   * subscription to api calls
   */
  private readonly subscription = new Subscription();

  /**
   * The AuthService constructor
   * @param accessControlService
   * @param configService
   * @param cookieService
   * @param cryptoService
   * @param errorHandlerService
   * @param httpClient
   * @param storageTrackerService
   * @param router
   */
  constructor(
    private readonly accessControlService: AccessControlService,
    private readonly configService: ConfigService,
    private readonly cookieService: CookieService,
    private readonly cryptoService: CryptoService,
    private readonly errorHandlerService: ErrorHandlerService,
    private readonly httpClient: HttpClient,
    private readonly storageTrackerService: StorageTrackerService,
    private readonly router: Router,
    @Inject('Window') private window: Window,
  ) {
    this.userProfile = JSON.parse(localStorage.getItem(LocalStorageEnum.USER_PROFILE) || '{}');
    // get API base URL
    this.apiBaseUrl = this.configService.getAPIBaseUrl;
    this.appEndpoints = this.configService.getEndpointsByModule('appEndpoints');
  }

  public get getAuthToken(): string {
    this.userProfile = this.getUserProfile();
    return this.userProfile?.hgApiToken ? this.userProfile.hgApiToken.trim() : '';
  }

  /**
   * This method is used for checking whether session is valid or not
   * Calculation :
   *   1: User Profile, Auth0 Domain should be exists.
   *   2: EXPIRES_AT value should be greater than current epoch Date time.
   * @return {Observable} isSessionValidSubject
   * @memberof AuthService
   */
  public isSessionValid(): Observable<boolean> {
    const userProfile = JSON.parse(localStorage.getItem(LocalStorageEnum.USER_PROFILE) as string);
    const userDomain = localStorage.getItem(LocalStorageEnum.AUTH0_DOMAIN) as string;
    const expiresAt = localStorage.getItem(LocalStorageEnum.EXPIRES_AT);
    const tokenExpiredAt = expiresAt ? this.cryptoService.decrypt(expiresAt) : false;
    let isValidToken = false;
    if (tokenExpiredAt && isNaN(Date.parse(tokenExpiredAt)) === false) {
      const tokenExpiredValue = new Date(tokenExpiredAt as string);
      isValidToken = Math.abs(Math.round(tokenExpiredValue.getTime() - Date.now()) / (1000 * 60)) >= 0;
    }
    this.isSessionValidSubject.next((!userProfile || !userDomain || !isValidToken) ? false : true);
    return this.isSessionValid$;
  }

  /**
   * Utility method to get the customer id
   * @returns Customer Id of logged in user else empty string
   */
  public getCustomerId(): string {
    const userProfile: IUserProfileBasedOnFields = this.getUserProfileBasedOnFields([UserProfileFieldsEnum.HG_CUSTOMER]);
    let clientId = '';
    if (userProfile[UserProfileFieldsEnum.HG_CUSTOMER] && userProfile[UserProfileFieldsEnum.HG_CUSTOMER]?.clientId) {
      clientId = userProfile[UserProfileFieldsEnum.HG_CUSTOMER].clientId.toString().trim();
    }
    return clientId;
  }

  /**
   * This method is used to get default Auth0 Popup Configurations
   *
   * @private
   * @param {string} email
   * @return {*}  {Auth0LockConstructorOptions}
   * @memberof AuthService
   */
  private getAuth0DefaultOptions(): Auth0LockConstructorOptions {
    return {
      container: 'authLoginContainer', // id for the html container
      avatar: null,
      auth: {
        redirect: true,
        responseType: 'token',
        params: {
          scope: 'openid',
          tenant: this.configService.auth0Tenant,
        },
        autoParseHash: false,
      },
      theme: {
        primaryColor: '#68BDCF', // Button color
        logo: '',
      },
      allowSignUp: false,
      allowForgotPassword: true,
      languageDictionary: {
        title: '', // Remove title from header
      },
      prefill: {
        email: this.userEmail,
        username: this.userEmail,
      },
      configurationBaseUrl: 'https://cdn.auth0.com',
      rememberLastLogin: false,
    };
  }

  /**
   * This method is used for initializing new Auth0Lock
   * @param {string} domain Domain will change for regular and custom
   * @param {string} options
   * @memberof AuthService
   */
  private initializeLock(domain: string, options: Auth0LockConstructorOptions = {}): Auth0LockStatic {
    return new Auth0Lock(environment.AUTH0.clientId, domain, options);
  }

  /**
   * This method is used for initializing Auth0 locked widget
   * based on the domain name.
   * For Ignite user => Legacy federated Auth0 domain
   * For User name & Password Authentication => Custom domain
   * @param {string} domain (either legacy federated domain or custom domain)
   * @param {string} email
   * @memberof AuthService
   */
  public initializeLockWidget(email: string, domain: string = environment.AUTH0.domain): void {
    localStorage.setItem(LocalStorageEnum.AUTH0_DOMAIN, domain || environment.AUTH0.domain);
    this.userEmail = email;
    this.lock = this.initializeLock(domain, this.getAuth0DefaultOptions());
    this.lock.show();
  }

  /**
   * This method is used to obtain for legacy federated domains.
   * @return Array of federated domains
   * @memberof AuthService
   */
  public getLegacyFederatedDomains(): Observable<Array<string>> {
    return this.httpClient.get<Array<string>>(`${this.configService.getAPIBaseUrl}${this.appEndpoints.legacyFederatedDomain}`);
  }

  /**
   * This method will return clientId from Auth0 User Profile or an empty string.
   * If hg-customer property exists in Auth0 User Profile then return hg-customer.clientId.
   * If hg-customer property not exists but Auth0 User Profile exists in Auth0 User Profile
   * then return userProfile.clientID.
   * If Auth0 User Profile not exists then return empty string.
   * @return {*}
   * @memberof AuthService
   */
  public getClientId(): string {
    const userProfile: IUserProfileBasedOnFields = this.getUserProfileBasedOnFields([UserProfileFieldsEnum.CLIENT_ID, UserProfileFieldsEnum.HG_CUSTOMER]);
    let clientId: string = '';
    if (userProfile[UserProfileFieldsEnum.HG_CUSTOMER]?.clientId) {
      clientId = userProfile[UserProfileFieldsEnum.HG_CUSTOMER].clientId.toString();
    } else if (userProfile.clientID) {
      clientId = userProfile.clientID;
    }
    return clientId;
  }

  /**
   * This method will return Auth0 User Profile which is stored in Local storage.
   *
   * @return {object} auth0.Auth0UserProfile or undefined
   * @memberof AuthService
   */
  public getUserProfileBasedOnFields(userProfileFields: string[]): IUserProfileBasedOnFields {
    let metaUserInfo = JSON.parse(localStorage.getItem(LocalStorageEnum.USER_PROFILE) || '{}');
    if (!Object.keys(metaUserInfo).length) return metaUserInfo;
    let userInfo: IUserProfileBasedOnFields = {
      email: userProfileFields.includes(UserProfileFieldsEnum.EMAIL) ? this.cryptoService.decrypt(metaUserInfo.email) : '',
      name: userProfileFields.includes(UserProfileFieldsEnum.NAME) ? this.cryptoService.decrypt(metaUserInfo.name) : '',
      clientID: userProfileFields.includes(UserProfileFieldsEnum.CLIENT_ID) ? this.cryptoService.decrypt(metaUserInfo.clientID) : '',
      [UserProfileFieldsEnum.HG_CUSTOMER]: {
        clientId: '',
        clientName: '',
        clientCode: '',
      },
    };
    if (metaUserInfo[UserProfileFieldsEnum.HG_CUSTOMER] && userProfileFields.includes(UserProfileFieldsEnum.HG_CUSTOMER)) {
      userInfo = {
        ...userInfo,
        [UserProfileFieldsEnum.HG_CUSTOMER]: {
          clientId: metaUserInfo[UserProfileFieldsEnum.HG_CUSTOMER].clientId ? this.cryptoService.decrypt(metaUserInfo[UserProfileFieldsEnum.HG_CUSTOMER].clientId.toString()) : '',
          clientName: metaUserInfo[UserProfileFieldsEnum.HG_CUSTOMER].clientName ? this.cryptoService.decrypt(metaUserInfo[UserProfileFieldsEnum.HG_CUSTOMER].clientName) : '',
          clientCode: metaUserInfo[UserProfileFieldsEnum.HG_CUSTOMER].clientCode ? this.cryptoService.decrypt(metaUserInfo[UserProfileFieldsEnum.HG_CUSTOMER].clientCode) : '',
        },
      };
    }
    return userInfo;
  }

  /**
   * This method will return Auth0 User Profile which is stored in Local storage.
   *
   * @return {object} auth0.Auth0UserProfile or undefined
   * @memberof AuthService
   */
  public getUserProfile(): IAuth0Profile {
    return JSON.parse(localStorage.getItem(LocalStorageEnum.USER_PROFILE) || '{}');
  }

  /**
   * This method is used to get New Auth0 token for an authenticate user.
   * Get a new token from Auth0 for an authenticated user.
   * The poll interval between checks to checkSession() should be at least 15 minutes
   * between calls to avoid any issues in the future with rate limiting of this call.
   * @memberof AuthService
   */
  public refreshSession(): Observable<any> {
    const userDomain: string = localStorage.getItem(LocalStorageEnum.AUTH0_DOMAIN) as string;
    if (!userDomain) return throwError(() => new Error('USER_DOMAIN_NOT_EXISTS'));
    const authParamsOptions: Auth0LockAuthParamsOptions = {};
    const auth0LockConstructorOptions: Auth0LockConstructorOptions = {
      auth: {
        redirectUrl: `${window.location.protocol}//${window.location.host}/login`,
        responseType: 'token',
        params: {
          scope: 'openid',
          tenant: this.configService.auth0Tenant,
        },
      },
    };
    const auth0CheckSessionPromise = new Promise((resolve, reject) => {
      this.lock = this.initializeLock(userDomain, auth0LockConstructorOptions);
      this.lock.checkSession(authParamsOptions, async (err: auth0.Auth0Error, authResult: AuthResult | undefined) => {
        if (err) {
          console.log(`<AuthService> - <refreshSession> Error while auth0 checkSession at ${new Date()} : ${err}`);
          reject(err);
        } else {
          console.log(`<AuthService> - <refreshSession> auth0 checkSession at ${new Date()} : Successful`);
          resolve(authResult);
        }
      });
    });

    return forkJoin([auth0CheckSessionPromise, this.invokeRefreshToken()]);
  }

  /**
   * This method is used for fetching the Auth0 user info by using Auth0 lock getUserInfo()
   * Auth0 user information is stored in local storage.
   * @param {AuthResult} authResult
   * @memberof AuthService
   */
  public fetchUserProfileInfo(accessToken: string, lock?: boolean): Promise<auth0.Auth0UserProfile> {
    if (lock) {
      const userDomain: string = localStorage.getItem(LocalStorageEnum.AUTH0_DOMAIN) as string;
      this.lock = this.initializeLock(userDomain, {
        auth: {
          redirectUrl: `${this.window.location.origin}/login`,
          responseType: 'token',
          params: {
            scope: 'openid',
            tenant: 'healthgrades-dev',
          },
        },
      });
    }
    console.log('<AuthService> - <fetchUserProfileInfo> Get User Info...');
    return new Promise((resolve, reject) => {
      this.lock?.getUserInfo(accessToken, (error: auth0.Auth0Error, profile: auth0.Auth0UserProfile) => {
        if (error) {
          console.error('<AuthService> - <fetchUserProfileInfo> Auth service on user authenticated :: getUserInfo :: ERROR', error);
          reject(error);
        }
        resolve(profile);
      });
    });
  }

  /**
   *
   * This method is used for updating the User Profile information with encrypted format in Local Storage.
   * @param {IAuth0Profile} metaUserInfo
   * @memberof AuthService
   */
  private saveUserProfileToLocalStorage(metaUserInfo: IAuth0Profile): void | Error {
    try {
      let userProfile: Record<string, any> = {
        email: metaUserInfo.email ? this.cryptoService.encrypt(metaUserInfo.email) : '',
        picture: metaUserInfo.picture,
        nickname: metaUserInfo.nickname ? this.cryptoService.encrypt(metaUserInfo.nickname) : '',
        name: metaUserInfo.name ? this.cryptoService.encrypt(metaUserInfo.name) : '',
        hgApiToken: metaUserInfo.hgApiToken,
        hgApiTokenId: metaUserInfo.hgApiTokenId,
        hgCredential: {
          user_email: metaUserInfo.hgCredential.user_email ? this.cryptoService.encrypt(metaUserInfo.hgCredential.user_email) : '',
        },
        clientID: this.cryptoService.encrypt(metaUserInfo.clientID),
      };
      if (metaUserInfo?.identities?.length > 0 && metaUserInfo?.identities[0].connection) {
        localStorage.setItem(LocalStorageEnum.CONNECTION, this.cryptoService.encrypt(metaUserInfo?.identities[0].connection));
      }
      localStorage.setItem(LocalStorageEnum.USER_PROFILE, JSON.stringify(userProfile));
      localStorage.setItem(LocalStorageEnum.EXPIRES_AT, this.cryptoService.encrypt(metaUserInfo.hgCredential.expires_datetime));
    } catch (error) {
      console.log('<AuthService> - <saveUserProfileToLocalStorage> Error occurred while persisting User profile information in local storage...', error);
      throw error;
    }

  }


  /**
   * This function is called in case of browser refresh / route change / login navigation start.
   * Fetch the access_token/id_token from url and Auth0 domain from local storage.
   * Use to complete authentication flow when autoParseHash is false.
   * On success in_token and user_profile is set to local storage.
   * @memberof AuthService
   */
  public resumeAuth(): Observable<IUserProfileWithClientList> {
    const userDomain: string = localStorage.getItem(LocalStorageEnum.AUTH0_DOMAIN) as string;
    const hash: string = window.location.hash;
    const auth0SuccessCallbackPromise: Promise<auth0.Auth0UserProfile> = new Promise((resolve, reject) => {
      /* istanbul ignore next */
      this.lock = this.initializeLock(userDomain);
      this.lock?.resumeAuth(hash, (error: auth0.Auth0Error, authResult: AuthResult) => {
        if (error) reject(error);
        else resolve(this.fetchUserProfileInfo(authResult?.accessToken));
      });
    });

    return from(auth0SuccessCallbackPromise).pipe(
      tap(()=> this.authenticationInProgressSubject.next(true)),
      switchMap((userProfile: auth0.Auth0UserProfile): Observable<IUserProfileWithClientList> => {
        return of({
          userProfile: userProfile as IAuth0Profile,
          clientDetails: null,
        });
      }));
  }

  /**
   * loginWithRedirect : This method is called by AuthGuard once waitForHandleAuthCallbackToComplete is completed
   * 1. Update User Profile info in Local Storage
   * 2. navigate user to respective route
   * @param {IAuth0Profile} userProfileWithClientDetails
   * @memberof AuthService
   */
  public loginWithRedirect(userProfileWithClientDetails: IUserProfileWithClientList): Observable<string> {
    try {
      if (userProfileWithClientDetails.userProfile) {
        this.saveUserProfileToLocalStorage(userProfileWithClientDetails.userProfile);
        // initialize gain insights
        return this.accessControlService.definePermissions().pipe(switchMap(() => {
          return this.accessControlService.getLandingPageBasedOnUserScopes().then((landing: string) => landing);
        }));
      } else {
        this.authenticationInProgressSubject.next(false);
        return of(`/${MainNavigationEnum.LOGIN}`);
      }
    } catch (error) {
      console.log('<AuthService> - <loginWithRedirect> Caught exception in login with Redirect method...', error);
      this.authenticationInProgressSubject.next(false);
      return  of(`/${MainNavigationEnum.LOGIN}`);
    }
  }

  /**
  *
  * This method is used to update client info along with user profile information in Local Storage.
  * @param {IAuth0ClientInfo} clientInfo
  * @memberof AuthService
  */
  public updateClientInfo(clientInfo: IAuth0ClientInfo): void {
    const userProfile = this.getUserProfile();
    localStorage.setItem(LocalStorageEnum.USER_PROFILE, JSON.stringify({
      ...userProfile,
      ... {
        [UserProfileFieldsEnum.HG_CUSTOMER]: {
          clientId: this.cryptoService.encrypt(clientInfo.clientId.toString()),
          clientName: this.cryptoService.encrypt(clientInfo.clientName),
          clientCode: this.cryptoService.encrypt(clientInfo.clientCode),
        },
      },
    }));
  }

  /**
   * This method is called once user reached idle timeout and every 10 Mins to acquire new HG-API Token
   * @return {*}
   * @memberof AuthService
   */
  public invokeRefreshToken(): Observable<any> {
    console.log('<AuthService> - <invokeRefreshToken> Invoking refresh token ...');
    const userProfile = JSON.parse(localStorage.getItem(LocalStorageEnum.USER_PROFILE) as string);
    const userDomain = localStorage.getItem(LocalStorageEnum.AUTH0_DOMAIN) as string;
    if (userProfile && userProfile?.hgApiTokenId && userDomain) {
      const authHgSessionTokenId = userProfile.hgApiTokenId;
      const accessToken = userProfile?.hgApiToken;
      let httpHeaders = new HttpHeaders({ 'Authorization': `Bearer ${accessToken}` });
      return this.httpClient.post<Record<string, any>>(`${this.apiBaseUrl}${this.appEndpoints.sessionRefresh}`, { tokenId: authHgSessionTokenId }, { headers: httpHeaders })
        .pipe(map((json: any) => {
          console.log('<AuthService> - <invokeRefreshToken> Invoked Refresh Token Successfully...', new Date());
          return json; //Returning observable
        }), catchError(this.errorHandlerService.errorHandler));
    } else {
      return throwError(() => new Error('USER_DOMAIN_NOT_EXISTS'));
    }
  }

  /**
   * Clearing Session
   * @memberof AuthService
   */
  private clearSession(): void {
    localStorage.clear();
    this.cookieService.deleteAll('/');
    sessionStorage.clear();
  }

  /**
   * This method is used for logging out from the Auth0 session
   * After logout from Auth0 session is done clear out cookies and local storage
   * @memberof AuthService
   */
  public logout(): void  {
    /**
     * Keeping this code in comment because it can be use for single-logout function in future. If we are not
     * going for Single Logout then need to delete the commented code.
     */

    /* const userDomain = localStorage.getItem(LocalStorageEnum.AUTH0_DOMAIN) as string;
    if (userDomain) {
      const auth0LockConstructorOptions: Auth0LockConstructorOptions = {
        auth: {
          redirectUrl: `${window.location.protocol}//${window.location.host}/login`,
          responseType: 'token',
          params: {
            scope: 'openid',
            tenant: this.configService.auth0Tenant,
          },
        },
      };
      this.lock = this.initializeLock(userDomain, auth0LockConstructorOptions);
      this.lock.logout({
        returnTo: `${window.location.protocol}//${window.location.host}`,
        client_id: environment.AUTH0.clientId,
        federated: false,
      });
    } else {
      this.router.navigateByUrl('/');
    } */
    this.storageTrackerService.stop();
    this.clearSession();
    this.logoutSubject$.next(true);
    this.logoutSubject$.complete();
    // Set to empty string if hash is present in URL bar before logout
    if (this.window.location.hash) this.window.location.hash = '';
    this.router.navigate([`/${MainNavigationEnum.LOGIN}`], { onSameUrlNavigation: 'reload' });
  }

  /**
   * A callback method that performs custom clean-up, invoked immediately before an instance is destroyed.
   */
  public ngOnDestroy(): void {
    this.subscription.unsubscribe();
  }
}
