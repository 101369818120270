<div class="row g-0 login-container align-items-center justify-content-center">
  <div class="col-sm-6 col-md-4 col-12 bg-white">
    <img class="d-block wbmd-logo-container mx-auto p-2 my-3" src="/assets/images/wbmd-logo.svg" alt="WebMD Ignite Growth Platform Logo">
    <!-- Pre Auth Login Container -->
    <form [formGroup]="preAuthLoginContainer" id="preAuthLoginContainer" class="p-5 pt-0" (ngSubmit)="onSubmitUserEmail()" *ngIf="!preAuthLoginFormSubmitted">
      <div class="mb-3">
        <label for="user-name" class="form-label">Email address</label>
        <input type="email" formControlName="email" class="form-control text-lowercase" id="user-name" placeholder="yours@example.com">
      </div>
      <button type="submit" *ngIf="preAuthLoginContainer.valid && !preAuthLoginContainer.controls.errors" class="btn btn-primary" id="next-btn">Next</button>
    </form>
    <!-- Auth0 Lock Container -->
    <div id="authLoginContainer" [hidden]="!(preAuthLoginContainer.valid && preAuthLoginFormSubmitted)">
        <p class="text-center" id="auth-login-message"><strong class="p-2">&#10004;</strong>Thanks for logging in.</p>
    </div>
    <img class="w-100 h-100" src="/assets/images/login-bottom.svg"/>
  </div>
</div>
