/**
 * This enum is used for referring Primary Navigation URL Path.
 */
export enum MainNavigationEnum {
  CLIENT_LIST = 'client-list',
  LOGIN = 'login',
  HOME = 'home',
  API_TOKENS = 'api-tokens',
  USER_MANAGEMENT = 'user-management',
  CREATE_USER = 'create-user',
  EDIT_USER = 'edit-user',
  UNAUTHORIZED = 'unauthorized',
}
