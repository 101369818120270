<div class="page-title-container bg-white py-2 ps-1" *ngIf="pageTitleConfig?.showPageTitleBar">
  <div class="row g-0">
    <!-- Row: 1, Column: 1 => Title Bar Section -->
    <div class="page-title-name-wrapper" [ngClass]="pageTitleConfig?.pageTitleWrapperClass || 'col-8 col-md-8 align-items-start'">
      <!-- Search Bar -->
      <input *ngIf="pageTitleConfig?.hasOwnProperty('input') && pageTitleConfig?.input?.display"
        [(ngModel)]="lookupWord" (ngModelChange)="this.searchStringUpdate.next($event)" class="form-control"
        placeholder="{{pageTitleConfig?.input?.placeholder}}" />
      <!-- Page Title Back button -->
      <p class="mb-1 back-btn-caption font-size-14 link-nav"
        *ngIf="pageTitleConfig && pageTitleConfig?.hasOwnProperty('backBtn')">
        <a href={{pageTitleConfig.backBtn?.stateName}}  [routerLink]="pageTitleConfig.backBtn?.stateName" id="pageTitleConfig?.id">
          <img class="img-fluid back-arrow" src="assets/images/back-button.svg" id="app-header-pgtitle-ic-back" />
        </a>
        <span class="d-inline-block align-middle ms-1">{{pageTitleConfig.backBtn?.label}}</span>
      </p>
      <!-- Page Title -->
      <h2 class="text-capitalize mb-0 d-inline-block" *ngIf="pageTitleConfig && pageTitleConfig?.title?.label">
        <span class="d-inline-block fw-bold text-truncate font-size-24" id="app-header-pgtitle-title"
          placement="bottom" ngbTooltip="{{pageTitleConfig.title?.label}}" [disableTooltip]="pageTitleConfig.title?.disableTooltip || false">
          {{pageTitleConfig.title?.label}}
        </span>
      </h2>
    </div>
    <!-- Row: 1, Column: 2 => Action Bar Section -->
    <div id="{{pageTitleConfig?.id}}" [ngClass]="pageTitleConfig?.pageTitleActionWrapperClass || 'col-12 col-md-4 align-items-end'" 
      *ngIf="pageTitleConfig?.hasOwnProperty('options')">
      <ul class="list-inline action-bar mb-0 mt-1 font-size-14 text-end me-2">
        <li class="list-inline-item fw-bold" *ngFor="let option of pageTitleConfig?.options; let i = index;">
          <ng-template [ngIf]="!option.display">
            <!-- Page Title -->
            <span class="font-size-16" id="{{option.id}}" (click)="btnClickCallback({type: option.type, key: option?.value})"
              *ngIf="option?.label && option.type === 'text' && !option.display">{{option?.label}}: 
              <span *ngIf="option?.label && option.type === 'text' && !option.display"
                [ngClass]="i === 0 ? 'fw-bold' : ''">{{option?.value}}
              </span>
            </span>
            <!-- Action button bar With out Permission Scope defined -->
            <ng-template [ngIf]="option.type === 'button' && !option.permissionScope">
              <button id="{{pageTitleConfig?.backBtn?.id}}"
                class="btn" [ngClass]="option?.cssClass ||'fw-bold btn-primary'" 
                (click)="btnClickCallback({type: option.type, key: option?.value, id: option?.id, routerLink:option?.routerLink})">
                <span><i *ngIf="option?.icon" [ngClass]="option.icon"></i>{{option?.value}}</span>
              </button>
            </ng-template>
            <!-- Action button bar With Permission Scope defined -->
            <ng-template [ngIf]="option.type === 'button' && option.permissionScope">
              <button *ngxPermissionsOnly="option.permissionScope" id="{{pageTitleConfig?.backBtn?.id}}"
                class="btn" [ngClass]="option?.cssClass ||'fw-bold btn-primary'" 
                (click)="btnClickCallback({type: option.type, key: option?.value, id: option?.id, routerLink:option?.routerLink})">
                <span><i *ngIf="option?.icon" [ngClass]="option.icon"></i>{{option?.value}}</span>
                <!-- Badge -->
                <span *ngIf="badgeMessageText" [ngClass]="option?.alertBadge?.cssClass" class="d-flex align-items-center justify-content-center position-absolute alert-badge translate-middle rounded-circle">
                  <span class="visually-hidden">{{badgeMessageText}}</span>
                  {{badgeMessageText}}
                </span>
              </button>
            </ng-template>
          </ng-template>
        </li>
        <li class="list-inline-item fw-bold font-size-16" *ngIf="pageTitleConfig?.hasOwnProperty('dropdown')">
          <div ngbDropdown class="d-inline-block">
            <a class="link-primary" id="pageTitleConfig?.dropdown.id" ngbDropdownToggle>Share</a>
            <div ngbDropdownMenu aria-labelledby="pageTitleConfig?.dropdown.id" class="cs-dd-model">
              <a ngbDropdownItem *ngFor="let option of pageTitleConfig?.dropdown?.value"
                (click)="selectItem(option?.value)">{{option?.value}}</a>
            </div>
          </div>
        </li>
        <li class="list-inline-item fw-bold" *ngIf="pageTitleConfig?.canDelete"
          (click)="btnClickCallback({type: 'link', key: pageTitleConfig?.title?.label})">
          <a class="link-primary" id="app-header-pgtitle-option-lnk-label-delete" href="javascript:void(0);">
            <i class="bi bi-trash"></i>
          </a>
        </li>
        <ng-template *ngFor="let option of pageTitleConfig?.options">
          <li class="status-display font-size-12" *ngIf="option.display">
            <span *ngIf="option.type !== 'date'"><b>{{option?.label}}:</b> {{option?.value}}</span>
            <span *ngIf="option.type === 'date'"><b>{{option?.label}}:</b>
              {{option?.value | date : "dd MMM yyyy"}}, {{option?.value | date : "hh:mm a"}} {{option?.valueSuffix}}
            </span>
          </li>
        </ng-template>
      </ul>
    </div>
    <!-- Row: 2, Column: 1 => Sub Title Bar Section -->
    <div *ngIf="pageTitleConfig?.subTitle?.label" [ngClass]="pageTitleConfig?.pageTitleActionWrapperClass ||'col-12 ps-md-0'">
      <p class="mb-0 d-inline-block" *ngIf="pageTitleConfig?.hasOwnProperty('subTitle')">
        <span class="d-inline-block text-truncate font-size-14" id="app-header-pgtitle-sub-title" placement="bottom"
          ngbTooltip="{{pageTitleConfig?.subTitle?.label}}" [disableTooltip]="pageTitleConfig?.subTitle?.disableTooltip || false">
          {{pageTitleConfig?.subTitle?.label}}
        </span>
      </p>
    </div>
  </div>
</div>