// Core Modules
import { Injectable } from '@angular/core';

// External Modules
import { Cache, CacheFactory } from 'cachefactory';

// Services
import { ConfigService } from './config.service';

/**
 * Cache Service
 */
@Injectable()
export class CacheService {
  /**
   * cacheFactoryConfig to get cache factory config
   */
  cacheFactoryConfig: Record<string, any>;

  /**
   * The constructor method
   * @param cacheFactory Cache factory
   * @param configService Config service
   */
  constructor(
    private readonly cacheFactory: CacheFactory,
    private readonly configService: ConfigService,
  ) {
    this.cacheFactoryConfig = this.configService.getContents('cacheFactoryConfig');
  }

  /**
   * Check for cache existance and returns flag
   * @param key combination of request criteria and client id
   * @returns undefined if key is exists else returns true
   */
  public isCacheExists(key: string): boolean {
    return this.cacheFactory.exists(key);
  }

  /**
   * Setup cache factory for each key and return instance
   * @param key combination of request criteria and client id
   * @returns Returns instance of cache factory
   */
  public createCache(key: string): Cache {
    return this.cacheFactory.createCache(key, {
      maxAge: this.cacheFactoryConfig.cacheMaxAge, // e.g. 1 hour = 60 * 60 * 1000
      cacheFlushInterval: this.cacheFactoryConfig.cacheFlushInterval, // This cache will clear itself every hour
      deleteOnExpire: 'aggressive',
    });
  }

  /**
   *
   * @param key combination of request criteria and client id
   * @returns Returns cached data by key
   */
  public getCacheData(key: string): Cache | any {
    return this.cacheFactory.get(key).get(key);
  }

  /**
   * destroy cache from memory
   */
  public destroyAll(): void {
    this.cacheFactory.destroyAll();
  }

  /**
   * Clear cache by key
   * @param key key to destroy cache
   */
  public destroyCacheByKey(key: string): void {
    this.cacheFactory.destroy(key);
  }

}
