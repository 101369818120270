// Core Modules
import { Component, Input } from '@angular/core';

// External Modules
import { Observable } from 'rxjs';

// Services
import { AuthService } from 'src/app/core/services/auth.service';
import { ConfigService } from '../../core/services/config.service';

// Models
import { HeaderContentModel } from '../models/header-content.model';

/**
 * Header Component
 */
@Component({
  selector: 'app-header',
  templateUrl: './header.component.html',
  styleUrls: ['./header.component.scss'],
})
export class HeaderComponent {

  /**
   * headerContents to get header contents model
   */
  public headerContents: HeaderContentModel;
  /**
   * isMenuCollapsed for menu collapse: true/false
   */
  public isMenuCollapsed = false;

  /**
   * hideSettings is for hiding the Home and logout icon
   */
  @Input() hideSettings?: Observable<boolean>;

  /**
   * The constructor method
   * @param configService config service
   */
  constructor(
    private configService: ConfigService,
    public authService: AuthService,
  ) {
    // Load the header static contents from app-contents.json file
    this.headerContents = this.configService.getContents('header');
  }
}
