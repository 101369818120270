<!--Loader division-->
<div class="d-flex justify-content-center align-items-center h-100 min-vh-100" *ngIf="(auth.isAuthenticationInProgress$ | async)">
  <div class="spinner-border spinner-border-sm" role="status" id="authLoginProgressSpinner">
    <span class="visually-hidden">Loading...</span>
  </div>
</div>
<!--Header division-->
<div *ngIf="(hideNavigation$ | async) === false || (auth.isAuthenticationInProgress$ | async)" id="app-header">
  <app-header [hideSettings]="hideNavigation$"></app-header>
</div>
<!--Router outlet (display routes as per configuration in routing.module)-->
<div id="app-content" *ngIf="(auth.isClientNavigationInProgress$ | async) === false" [ngClass]="(hideNavigation$ | async) ? 'h-100 pt-0' : ''">
  <div class="mh-section-content" [ngClass]="{'h-100 w-100': (hideNavigation$ | async)}">
    <router-outlet></router-outlet>
  </div>
</div>
<!--Footer division-->
<div *ngIf="(hideNavigation$ | async) === false" class="mt-auto w-100">
  <app-footer></app-footer>
</div>
