<div id="home-container">
  <!--Cards division - displayed when either token or user management role is available-->
  <div *ngIf="allowDisplay" class="row">
    <!--Cards container - displays cards based on permission-->
    <ng-template [ngxPermissionsOnly]="card.permissionOnly" *ngFor="let card of cardConfig">
      <div class="col-12 col-md-4 col-sm-6 col-12 mb-3 align-items-stretch flex-shrink-1 d-flex">
        <div class="card">
          <div class="card-body d-flex flex-column p-0">
            <img class="w-100" src="{{card.src}}" [alt]="card.title" />
            <div class="container-fluid">
              <div class="row">
                <div class="col-12 py-2 d-flex justify-content-between align-items-center">
                  <span>
                    <img src="assets/images/tile-logo-icon.svg" alt="feature logo">
                    <span class="card-title align-middle ms-3" [innerHTML]="card.title"></span>
                  </span>
                  <a [routerLink]="card.goto" class="font-size-14">
                    <i><span><img src="assets/images/tile-arrow-icon.svg" alt="arrow icon"/></span></i>
                  </a>
                </div>
              </div>
              <div class="row mt-3 mb-1 flex-grow-1 flex-column">
                <div class="col-12 flex-grow-1">
                  <p class="card-text text-wrap font-size-14" [innerHTML]="card.description"> 
                  </p>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </ng-template>
  </div>
  <!--Message container - displayed when user does not have both token & user management roles-->
  <div *ngIf="!allowDisplay" class="restricted-container d-flex justify-content-center flex-column">
    <p class="font-size-20 mx-auto">Oops. You're not authorized for this level of access.</p>
  </div>
</div>
