// Core Modules
import { Component, OnInit } from '@angular/core';

// Services
import { ErrorHandlerService } from 'src/app/core/services/error-handler.service';

// Models
import { GenericErrorHandlerModel } from '../models/generic-error-handler.model';

// Enums
import { ErrorHandlerCodesEnum } from '../enum/error-handler-codes.enum';

/**
 * Unauthorized Component
 */
@Component({
  selector: 'app-unauthorized',
  templateUrl: './unauthorized.component.html',
  styleUrls: ['./unauthorized.component.scss'],
})
export class UnauthorizedComponent implements OnInit {
  /**
   * accessDeniedStatusCode to get access denied status code
   */
  private accessDeniedStatusCode = ErrorHandlerCodesEnum.FORBIDDEN;

  /**
   * contains data of type GenericErrorHandlerModel to show image , title and description
   */
  public errorhandlerData?: GenericErrorHandlerModel;

  /**
   * The constructor method
   * @param errorHandlerService error handler service
   */
  constructor(private errorHandlerService: ErrorHandlerService) {}

  /**
   * A callback method that is invoked immediately after the default change detector has checked the directive's data-bound properties for the first time, and before any of the view or content children have been checked.
   */
  public ngOnInit(): void {
    this.errorhandlerData = this.errorHandlerService.getErrorCodeData(
      this.accessDeniedStatusCode,
    );
  }
}
