<div class="flex-grow-1 user-settings-container border-left">
  <div class="d-flex align-items-center justify-content-center flex-grow-1 ps-4" id="app-header-ic-us">
    <span class="rounded-circle d-flex align-items-center justify-content-center">
      <span class="d-inline-block fw-bold font-size-16" id="app-header-us-title-initials">{{userNameInitials}}</span>
    </span>
    <div ngbDropdown [autoClose]="'outside'" placement="bottom-end" class="user-settings-dropdown d-inline-block">
      <i class="bi bi-chevron-down d-inline-block" id="app-header-lnk-us" ngbDropdownToggle></i>
      <ul ngbDropdownMenu aria-labelledby="app-header-lnk-us">
        <li class="dropdown-item d-flex align-items-center">
          <span class="rounded-circle d-flex align-items-center justify-content-center" id="app-header-lnk-user-name">
            <span class="d-inline-block fw-bold font-size-16 text-white"
              id="app-header-us-title-initials">{{userNameInitials}}</span>
          </span>
          <h6 class="d-inline-block ps-2 mb-0 font-size-14" id="app-header-us-mdl-useremail">{{userEmailName}}
          </h6>
        </li>
        <li>
          <hr class="dropdown-divider">
        </li>
        <li class="text-center">
          <a class="dropdown-item link-primary cursor-pointer fw-bold" id="app-header-us-btn-logout"
            (click)="logout()">{{headerContents.profileRedirectData.logout}}</a>
        </li>
      </ul>
    </div>
  </div>
</div>