/**
 * Token mapper
 * @returns the mapper for token
 */
export const tokenMapper = () => {
  return {
    'token-read': ['auth/me/tokens:read'],
    'token-write': ['auth/me/tokens:read', 'auth/me/tokens:write'],
  };
};
  