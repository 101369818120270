import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { ListViewComponent } from './list-view/list-view.component';
import { SearchComponent } from './search/search.component';
import { PaginationComponent } from './pagination/pagination.component';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { NgPipesModule } from 'ngx-pipes';
import { FooterComponent } from './footer/footer.component';
import { PageTitleComponent } from './page-title/page-title.component';
import { NgbDropdown, NgbDropdownModule, NgbModule, NgbToastModule, NgbTooltipModule } from '@ng-bootstrap/ng-bootstrap';

import { SessionExpiryNotificationComponent } from './session-expiry-notification/session-expiry-notification.component';
import { AuthLoginComponent } from './auth-login/auth-login.component';
import { HeaderComponent } from './header/header.component';
import { NgxPermissionsModule } from 'ngx-permissions';
import { RouterModule } from '@angular/router';
import { ToastsContainerComponent } from './toasts/toasts-container.component';
import { UserSettingsComponent } from './header/user-settings/user-settings.component';


@NgModule({
  declarations: [
    AuthLoginComponent,
    ListViewComponent,
    SearchComponent,
    PaginationComponent,
    HeaderComponent,
    FooterComponent,
    PageTitleComponent,
    SessionExpiryNotificationComponent,
    ToastsContainerComponent,
    UserSettingsComponent,
  ],
  imports: [
    CommonModule,
    FormsModule,
    NgPipesModule,
    NgbModule,
    NgbToastModule,
    NgxPermissionsModule,
    ReactiveFormsModule,
    RouterModule,
    NgbTooltipModule,
    NgbDropdownModule,
  ],
  exports: [
    ListViewComponent,
    SearchComponent,
    PaginationComponent,
    HeaderComponent,
    FooterComponent,
    PageTitleComponent,
    ToastsContainerComponent,
  ],
  providers: [NgbDropdown],
})
export class SharedModule { }
