// Core Modules
import { CanActivateFn, Router, UrlTree } from '@angular/router';
import { Inject, Injectable, inject } from '@angular/core';

// External Modules
import { Observable, catchError, finalize, from, lastValueFrom, of, switchMap, take, tap } from 'rxjs';

// Services
import { AuthService } from '../services/auth.service';
import { AccessControlService } from '../permission/service/access-control.service';
import { SessionManagerService } from '../services/session-manager.service';

// Models
import { IUserProfileWithClientList } from 'src/app/shared/models/auth0-types.model';

// Enums
import { MainNavigationEnum } from '../../../app/shared/enum/main-navigation-url.enum';

/**
 * Auth Guard
 */
@Injectable()
export class AuthGuardCanActivate {
  /**
   * The constructor method
   * @param accessControlService access control service
   * @param authService auth Service
   * @param router router
   * @param sessionManager session manager service
   * @param window window
   */
  constructor(
    private readonly accessControlService: AccessControlService,
    private readonly authService: AuthService,
    private readonly router: Router,
    private readonly sessionManager: SessionManagerService,
    @Inject('Window') private window: Window,
  ) {}

  /**
   * method to validate activated route
   * @returns session valid boolean: true/false
   */
  canActivate(): Observable<UrlTree | boolean> {
    if (this.window.location.href.includes('auth0_domain') && this.window.location.href.includes('access_token')) {
      const urlSearchParam: URLSearchParams = new URLSearchParams(this.window.location.search);
      const urlHashParam: URLSearchParams = new URLSearchParams(this.window.location.hash);
      this.authService.authenticationInProgressSubject.next(true);
      const auth0Domain: string = urlSearchParam.get('auth0_domain') || '';
      localStorage.setItem('auth0_domain', auth0Domain);
      const accessToken: string = urlHashParam.get('#access_token') || '';
      return from(this.authService.fetchUserProfileInfo(accessToken, true)).pipe(
        tap(() => this.authService.authenticationInProgressSubject.next(true)),
        switchMap((userProfile: auth0.Auth0UserProfile) => this.authService.loginWithRedirect({ userProfile: userProfile, clientDetails: null })),
        switchMap((navigateTo) => {
          if (!['/', `/${MainNavigationEnum.LOGIN}`].includes(navigateTo as string)) {
            this.sessionManager.setupSessionEvents();
          } else if (['/', `/${MainNavigationEnum.LOGIN}`].includes(navigateTo as string)) {
            this.sessionManager.stopWatching();
            this.authService.logout();
          }
          return this.router.navigate([navigateTo]);
        }),
        catchError((error) => {
          this.authService.authenticationInProgressSubject.next(false);
          console.log('<AuthGuard> - <canActivate> - Failed while resuming the auth Session', error);
          this.sessionManager.stopWatching();
          this.authService.logout();
          return this.router.navigate([`/${MainNavigationEnum.LOGIN}`]); 
        }),
        finalize(() => {
          this.authService.authenticationInProgressSubject.next(false);
        }),
      );
    } else if (this.window.location.href.includes('id_token') || this.window.location.href.includes('access_token') || this.window.location.href.includes('error')) {
      const urlSearchParam: any = new URLSearchParams(this.window.location.href);
      const stateParam = urlSearchParam.get('state');
      console.log(`<AuthGuard> - <canActivate> - Current stateParam is: ${stateParam}`);
      this.authService.authenticationInProgressSubject.next(true);
      return this.authService.resumeAuth().pipe(
        tap(() => this.authService.authenticationInProgressSubject.next(true)),
        switchMap((userProfileWithClientList: IUserProfileWithClientList) => this.authService.loginWithRedirect(userProfileWithClientList)),
        switchMap((navigateTo) => {
          if (!['/', `/${MainNavigationEnum.LOGIN}`].includes(navigateTo as string)) {
            this.sessionManager.setupSessionEvents();
          } else if (['/', `/${MainNavigationEnum.LOGIN}`].includes(navigateTo as string)) {
            this.sessionManager.stopWatching();
            this.authService.logout();
          }
          return this.router.navigate([navigateTo]);
        }),
        catchError((error) => {
          this.authService.authenticationInProgressSubject.next(false);
          console.log('<AuthGuard> - <canActivate> - Failed while resuming the auth Session', error);
          this.sessionManager.stopWatching();
          this.authService.logout();
          return this.router.navigate([`/${MainNavigationEnum.LOGIN}`]); 
        }),
        finalize(() => {
          this.authService.authenticationInProgressSubject.next(false);
        }),
      );
    } else {
      return this.authService.isSessionValid().pipe(
        take(1),
        switchMap((status: boolean) => {
          if (status) {
            this.authService.authenticationInProgressSubject.next(true);
            return lastValueFrom(this.authService.refreshSession().pipe(
              take(1),
              switchMap(() => this.accessControlService.definePermissions().pipe(
                switchMap(() => {
                  this.sessionManager.setupSessionEvents();
                  return this.accessControlService.getLandingPageBasedOnUserScopes().then((landingPage:string) => this.router.navigateByUrl(landingPage)); 
                }),
              )),
              catchError((error) => {
                this.authService.authenticationInProgressSubject.next(false);
                console.log('<AuthGuard> - <canActivate> - Failed while refreshing the session and navigating to specific page based on scope', error);
                this.sessionManager.stopWatching();
                this.authService.logout();
                return this.router.navigate([`/${MainNavigationEnum.LOGIN}`]);
              }),
              finalize(() => this.authService.authenticationInProgressSubject.next(false)),
            ));
          } else {
            this.authService.authenticationInProgressSubject.next(false);
            return of(true);
          }
        }),
      );
    }
  }
}

export const AuthGuard: CanActivateFn = () => inject(AuthGuardCanActivate).canActivate();
