<div class="list-view-table-wrapper table-responsive py-2 font-size-14">
  <!-- List View Table -->
  <table class="table table-hover" [ngClass]="settings.cssClass"
    role="table" [attr.aria-label]="settings.ariaLabel" [attr.aria-describedby]="settings.ariaLabelDesc"
    [attr.aria-rowcount]="items?.length">
    <thead>
      <tr>
        <th scope="col" [attr.id]="columnId.value?.id" [attr.aria-label]="columnId.value?.ariaLabel" 
          *ngFor="let columnId of headers | keyvalue | orderBy: 'value.order';let i = index" [attr.width]="columnId?.value?.width">
          <!-- sorting filter with dropdown -->
          <ng-container [ngSwitch]="columnId.value?.type">
            <ng-template [ngSwitchCase]="'dropdown'">
              <div [attr.id]="'list-view-th-dd-'+i" class="table-list-view-dropdown" ngbDropdown placement="bottom-end" autoClose="outside" container="body" [dropdownClass]="columnId.value.cssClass">
                <span class="d-inline-block align-middle mt-1" [attr.aria-label]="columnId.value?.ariaLabel">{{columnId.value.name}}</span>
                <a href="javascript:void(0)" class="list-view-filter-bar">
                  <i class="bi bi-filter-square px-2 d-inline-block align-middle font-size-16 text-dark" ngbDropdownToggle></i>
                </a>
                <div ngbDropdownMenu [attr.aria-labelledby]="columnId.value?.ariaLabel">
                  <ng-template *ngFor="let option of columnId.value?.options"
                  [ngxPermissionsOnly]="option?.permissionOnly"
                  [ngxPermissionsExcept]="option?.permissionExcept">
                    <a ngbDropdownItem href="javascript:void(0)"
                      [attr.id]="'list-view-th-dd-'+option?.value"
                      [ngClass]="selectedFilter === option.value ? 'active': ''"
                      (click)="callback({ type: 'filter', key: option?.key, value: option.value })"
                      [attr.aria-label]="option?.id"
                      [attr.aria-label]="option?.value">
                      {{option?.value}}
                    </a>
                  </ng-template>
                </div>
              </div>
            </ng-template>
            <ng-template [ngSwitchDefault]>
              <span class="d-inline-block align-middle font-size-14">{{columnId.value.name}}</span>
              <a href="javascript:void(0)" [class.active]="columnId.value.defaultSort === true" [ngClass]="{ 'd-none':columnId.value.isSort }"  class="sort-icon position-absolute"
              (click)="callback({ type: 'sorting', element: columnId })">
              <i [attr.id]="columnId?.value?.id+'-down-arrow'" [ngClass]="{ 'bi bi-caret-up-fill':columnId.value.active,'bi bi-caret-down-fill':!columnId.value.active }" class="px-2 pt-1 d-inline-block align-middle"></i>
              </a>
            </ng-template>
          </ng-container>
          <!-- sorting filter with dropdown -->
        </th>
        <th scope="col"  
          *ngIf="settings?.misc?.row">
        </th>
      </tr>
    </thead>
    <tbody *ngIf="!displayLoader && items?.length">
      <tr role="row" *ngFor="let row of items">
        <td *ngFor="let columnId of headers | keyvalue | orderBy: 'value.order'">
          <!-- Displaying header when header match with response -->
          <div *ngIf="headers?.columnId?.key !== undefined" class="d-flex">
            <ng-template>
              <i class="me-2 d-flex align-items-center" *ngIf="columnId?.value?.icon && row?.iconCss"
              [ngClass]="[columnId.value.icon, row.iconCss]"></i>
            </ng-template>
            <ng-container [ngSwitch]="columnId.value?.colType">
              <ng-template [ngSwitchCase]="'link'">
                <a href="javascript:void(0)" class="text-decoration-none" [ngClass]="columnId.value?.cssClass" (click)="callback({ type: 'link', value: row })">{{row[columnId.key]}}</a>
              </ng-template>
              <ng-template [ngSwitchCase]="'ngTemplate'">
                <ng-container [ngTemplateOutlet]="template" [ngTemplateOutletContext]="{ data: row, header: columnId.value, callback }"></ng-container>
              </ng-template>
              <ng-template [ngSwitchCase]="'toggle'" >
                <div class="form-check form-switch">
                  <input class="form-check-input" type="checkbox" id="flexSwitchCheckChecked" [attr.checked]="row?.isActive? true: null" (change)="callback({ type: row.type, value: row.value, data: row })">
                </div>
              </ng-template>
              <ng-template [ngSwitchDefault]>
                <span (click)="callback({ type: 'text', value: row })">{{row[columnId.key]}}</span>
              </ng-template>
            </ng-container>
          </div>
        </td>
        <td>
          <div class="d-flex align-items-center" [ngClass]="settings?.row?.actions.length === 1 ? 'justify-content-end': 'justify-content-between'">
            <!-- User Action Bar -->
            <ng-container *ngFor="let action of settings?.row?.actions" [ngSwitch]="action?.type">
              <a *ngxPermissionsOnly="'user-write'" href="javascript:void(0)">
                <img src="assets/images/shape.svg" alt="delete" (click)="callback({ value: action.value, data: row })">
              </a>
            </ng-container>
          </div>
        </td>
      </tr>
    </tbody>
  </table>
  <!-- Spinner -->
  <div class="d-flex page-min-height" *ngIf="displayLoader || items?.length === 0">
    <div class="flex-grow-1 d-flex align-items-center justify-content-center">
      <div *ngIf="displayLoader" class="spinner-border spinner-border" role="status"></div>
      <p *ngIf="items?.length === 0 && !displayLoader">{{settings.recordsNotFound || 'No Records Found'}}</p>
    </div>
  </div>
</div>
