// Core Modules
import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';

// External Modules
import { map, Observable, catchError } from 'rxjs';
import { NgxPermissionsService } from 'ngx-permissions';

// Services
import { ConfigService } from '../../services/config.service';
import { ErrorHandlerService } from '../../services/error-handler.service';

// Models
import { AppEndpointsModel } from 'src/app/shared/models/endpoints.config.model';

// Enums
import { MainNavigationEnum } from '../../../shared/enum/main-navigation-url.enum';
import { IUserDetails } from 'src/app/shared/models/auth-api-response.model';

// Mappers
import { userMapper } from '../user-mapper';
import { tokenMapper } from '../token-mapper';

/**
 * Access Control Service
 */
@Injectable({
  providedIn: 'root',
})
export class AccessControlService {
  /**
   * get api endpoints
   */
  private appEndpoints: AppEndpointsModel;

  /**
   * get mapped application scopes
   */
  private applicationScopes: Record<string, any[]>;

  /**
   * The constructor method
   * @param configService config service
   * @param errorHandlerService error handler service
   * @param httpClient httpClient for api calls
   * @param permissionsService permission service
   */
  constructor(
    private readonly configService: ConfigService,
    private readonly errorHandlerService: ErrorHandlerService,
    private readonly httpClient: HttpClient,
    private readonly permissionsService: NgxPermissionsService,
  ) {
    this.applicationScopes = { ...userMapper(), ...tokenMapper() };
    this.appEndpoints = this.configService.getEndpointsByModule('appEndpoints');
  }

  /**
   * This method is used for fetching user scope information.
   * @return UserDetails
   */
  public getUserScopes(): Observable<IUserDetails> {
    return this.httpClient.get<IUserDetails>(`${this.configService.getAPIBaseUrl}${this.appEndpoints.userScopes}`)
      .pipe(
        map((data: IUserDetails) => data), catchError(this.errorHandlerService.errorHandler),
      );
  }

  /**
   * Load the permission for NgxPermission and return current user permissions.
   * @returns current user permissions.
   */
  public definePermissions(): Observable<boolean> {
    return this.getUserScopes().pipe(
      // get the user scope
      map((resp: IUserDetails): boolean => {
        // Filter the permission from mapper with user scope.
        const currentAppPermissions = Object.keys(this.applicationScopes).filter((key) =>
          this.applicationScopes[key].every((value: string) => resp.scopes.includes(value)),
        );
        currentAppPermissions.push('admin-app-read');
        // load the matched permission keys in the NgxPermissionService.
        this.permissionsService.loadPermissions(currentAppPermissions);
        return true;
      }), catchError(this.errorHandlerService.errorHandler),
    );
  }

  /**
   * Check if current user have permission for default landing.
   * @returns authorized route
   */
  async getLandingPageBasedOnUserScopes(): Promise<string> {
    const userLandingPageBasedOnUserScopes: boolean = await this.permissionsService.hasPermission(['admin-app-read']);
    if (userLandingPageBasedOnUserScopes) {
      return `/${MainNavigationEnum.HOME}`;
    }
    return `/${MainNavigationEnum.UNAUTHORIZED}`;
  }
}
