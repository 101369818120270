// Core Modules
import { CanActivateFn, UrlTree } from '@angular/router';
import { inject } from '@angular/core';

// External Modules
import { Observable } from 'rxjs';

// Services
import { SessionManagerService } from '../services/session-manager.service';

/**
 * Session Validation Guard
 */
export const SessionValidationGuard: CanActivateFn = (): Observable<boolean | UrlTree> => {
  /**
   * The constructor method
   * @param sessionManager session manager
   */
  const sessionManager = inject(SessionManagerService);
  
  /**
   * This session guard is used to validate the session is Alive on CanActivate hook.
   * CanActivate is Interface that a class can implement to be a guard deciding 
   * if a route can be activated. If all guards return true, navigation continues.
   * If any guard returns false, navigation is cancelled
   * @param next 
   * @param state 
   * @returns boolean
   */
  return sessionManager.validateSessionBeforeCanActivateRoute();
};
