// Core Modules
import { Component, OnInit } from '@angular/core';

// External Modules
import { NgxPermissionsService } from 'ngx-permissions';

// Enums
import { MainNavigationEnum } from '../shared/enum/main-navigation-url.enum';
import { IHomeCard } from './models/card-model';

@Component({
  selector: 'app-home',
  templateUrl: './home.component.html',
  styleUrls: ['./home.component.scss'],
})
export class AdminHomeComponent implements OnInit {

  /**
   * Configuration set in order to display cards
   */
  public cardConfig : Array<IHomeCard> = [];

  /**
   * Set to true if either of token or user role is present
   */
  public allowDisplay: boolean = false;

  /**
   * The constructor method
   * @param ngxPermissionsService permission service
   */
  constructor(private readonly ngxPermissionsService: NgxPermissionsService) {}

  /**
   * A callback method that is invoked immediately after the default change detector has checked the directive's data-bound properties for the first time, and before any of the view or content children have been checked.
   */
  public ngOnInit(): void {
    Promise.all([
      this.ngxPermissionsService.hasPermission('token-read'),
      this.ngxPermissionsService.hasPermission('user-read'),
    ]).then((result: Array<boolean>)=> {
      const [token, user] = result;
      this.allowDisplay = token || user;
      if (this.allowDisplay) this.cardConfig.push(
        {
          title: 'Token Management',
          src: '/assets/images/consumer-profiler-report-bg.png',
          description: `Allow users to view and create their own authenticated API Tokens.`,
          goto: `/${MainNavigationEnum.API_TOKENS}`,
          permissionOnly: 'token-read',
        },
        {
          title: 'User Management',
          src: '/assets/images/report_icon.svg',
          description: `Allow admins to view and modify user access.This includes managing roles and scopes and providing authenticated access to different clients.`,
          goto: `/${MainNavigationEnum.USER_MANAGEMENT}`,
          permissionOnly: 'user-read',
        },
      );
    });
  }
}
