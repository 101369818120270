// Core Modules
import { Component, OnInit, OnDestroy, AfterContentChecked, ChangeDetectorRef } from '@angular/core';
import { NavigationEnd, Router } from '@angular/router';

// External Modules
import { BehaviorSubject, filter, Subscription } from 'rxjs';

// Services
import { AuthService } from './core/services/auth.service';

// Enums
import { MainNavigationEnum } from './shared/enum/main-navigation-url.enum';

/**
 * App Component
 */
@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss'],
})

export class AppComponent implements OnInit, AfterContentChecked, OnDestroy {
  /**
   * router subscription
   */
  private routerSubscription = new Subscription();

  /**
   * Hide Navigation Subject
   */
  public hideNavigationSubject = new BehaviorSubject<boolean>(true);
  /**
   * hideNavigation$ observable for Hide Navigation emits
   */
  public hideNavigation$ = this.hideNavigationSubject.asObservable();

  /**
   * The constructor method
   * @param auth auth
   * @param sessionManager session manager
   * @param router router
   */
  constructor(
    public readonly auth: AuthService,
    private readonly router: Router,
    private readonly changeDetector: ChangeDetectorRef,
  ) {}

  /**
   * A callback method that is invoked immediately after the default change detector has checked the directive's data-bound properties for the first time, and before any of the view or content children have been checked.
   */
  public ngOnInit(): void {
    this.routerSubscription.add(
      this.router.events.pipe(filter(i => i instanceof NavigationEnd)).subscribe({
        next: (event: any) => {
          if ([`/${MainNavigationEnum.LOGIN}`, '/'].includes(event.urlAfterRedirects)) {
            this.hideNavigationSubject.next(true);
          } else {
            this.hideNavigationSubject.next(false);
          }
        },
        error: (e) => {
          console.log(`<AppComponent> - <ngOnInit> Error occurred during subscribing router events...`, e);
          this.hideNavigationSubject.next(false);
        },
        complete: () => this.hideNavigationSubject.complete(),
      }),
    );
  }

  /**
   * Respond after Angular checks the content projected into the directive or component.
   */
  ngAfterContentChecked(): void {
    this.changeDetector.detectChanges();
  }

  /**
   * A callback method that performs custom clean-up, invoked immediately before an instance is destroyed. 
   */
  public ngOnDestroy(): void {
    this.routerSubscription.unsubscribe();
    this.auth.authenticationInProgressSubject.unsubscribe();
    this.auth.clientNavigationInProgressSubject.unsubscribe();
  }

}
