// Core Modules
import { ActivatedRouteSnapshot, CanActivateFn, Router } from '@angular/router';
import { Injectable, inject } from '@angular/core';

// External Modules
import { Observable, catchError, finalize, lastValueFrom, map, of, switchMap, take } from 'rxjs';
import { NgxPermissionsService } from 'ngx-permissions';

// Services
import { AccessControlService } from '../permission/service/access-control.service';
import { AuthService } from '../services/auth.service';
import { SessionManagerService } from '../services/session-manager.service';

// Enums
import { MainNavigationEnum } from 'src/app/shared/enum/main-navigation-url.enum';

/**
 * Permission Guard
 */
@Injectable()
export class PermissionGuardCanActivate {
  /**
   * The constructor method
   * @param accessControlService access control service
   * @param authService auth service
   * @param permissionsService permission service 
   * @param router router
   * @param sessionManager session manager service
   */
  constructor(
    private readonly accessControlService: AccessControlService,
    private readonly authService: AuthService,
    private readonly permissionsService: NgxPermissionsService,
    private readonly router: Router,
    private readonly sessionManager: SessionManagerService,
  ) {}
  /**
   * method to validate activated route
   * @param next activate route
   * @returns permissions for route boolean: true/false
   */
  canActivate(
    next: ActivatedRouteSnapshot): Observable<boolean> | Promise<boolean> | boolean {
    const routePermissions = next.data?.permissions?.only as Array<string>;
    const redirectTo = next.data?.permissions?.redirectTo;
    return this.authorizeUser(routePermissions, redirectTo);
  }

  /**
   * Get the current user permission for app
   * @param routePermissions current route permission
   * @param redirectTo If fail redirect to this route
   * @returns
   */
  private getPermissions(routePermissions: string[], redirectTo: string): Observable<boolean>  {
    this.authService.authenticationInProgressSubject.next(true);
    if (Object.keys(this.permissionsService.getPermissions())?.length === 0 ) {
      // when browser refresh reschedule the refresh token poller
      return this.authService.refreshSession().pipe(
        take(1),
        switchMap(() => {
          this.sessionManager.setupSessionEvents();
          return this.accessControlService.definePermissions().pipe(
            switchMap(()=> this.permissionsService.hasPermission(routePermissions)),
            map((hasAccess: boolean) => {
              if (hasAccess) return true;
              else {
                this.router.navigateByUrl(redirectTo);
                return false;
              }
            }),
          );
        }),
        catchError((error) => {
          console.log('<PermissionGuard> - <canActivate> - Failed while refreshing the session and navigating to specific page based on scope', error);
          this.sessionManager.stopWatching();
          this.authService.logout();
          return this.router.navigate([`/${MainNavigationEnum.LOGIN}`]);
        }),
        finalize(() => {
          this.authService.authenticationInProgressSubject.next(false);
        }),
      );
    } else {
      this.router.navigateByUrl(redirectTo);
      return of(false);
    }
  }

  /**
   * Validate the if logged in user have route permission.
   * @param currentAppPermissions current user permission for app.
   * @param routePermissions current route permission.
   * @param redirectTo If fail redirect to this route.
   * @returns
   */
  private async authorizeUser(routePermissions: string[], redirectTo: string): Promise<boolean> {
    // check if routePermission contain any element of userPermission
    if (await this.permissionsService.hasPermission(routePermissions)) {
      return Promise.resolve(true);
    } else {
      return lastValueFrom(this.getPermissions(routePermissions, redirectTo));
    }
  }
}

export const PermissionGuard: CanActivateFn = (route: ActivatedRouteSnapshot) => inject(PermissionGuardCanActivate).canActivate(route);