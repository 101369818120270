/**
 * User mapper
 * @returns the mapper for user
 */
export const userMapper = () => {
  return {
    'user-read': ['auth/users:read'],
    'user-write': ['auth/users:read', 'auth/users:write'],
  };
};
  